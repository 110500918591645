/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, FormGroup, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextAreaInput } from "../shared/components/TextAreaInput";
import { useContainerStyles } from "./styles";

export const useAlergiesStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      "& > span": {
        margin: "1em 0",
      },
    },
    container: {
      width: "100%",
      display: "flex",
      alignItems: "stretch",
      "& div:nth-child(1)": {
        flexGrow: "1",
      },
      "& div:nth-child(2)": {
        flexGrow: "1",
        height: "160px",
        marginTop: "15px",
      },
    },
  })
);

type Props = {
  alergies?: string;
  includeSelectedInAlergies?: boolean;
  onAlergiesChange?: (v: string) => any;
  onSelectedAlergiesChange?: (v: string[]) => any;
  selectedAlergies?: string[];
};

export const AlergiesField: FC<Props> = ({
  alergies: existingAlergies = "",
  includeSelectedInAlergies = false,
  onAlergiesChange = () => {},
  onSelectedAlergiesChange = () => {},
  selectedAlergies: existingSelectedAlergies = []
}) => {
  const { t } = useTranslation('group-booking');
  const predefinedAlergies = [
    t("alergies.milk"),
    t("alergies.gluten"),
    t("alergies.nuts"),
    t("alergies.eggs"),
  ];

  const classes = useAlergiesStyles();
  const classesContainer = useContainerStyles();

  const [alergies, setAlergies] = useState(existingAlergies);
  const [selected, setSelected] = useState<string[]>(existingSelectedAlergies);

  useEffect(() => {
    if(!includeSelectedInAlergies && alergies && !existingAlergies) {
      setAlergies(existingAlergies);
    }
  }, [existingAlergies]);

  useEffect(() => {
    if(!includeSelectedInAlergies && selected.length !== existingSelectedAlergies.length) {
      setSelected(existingSelectedAlergies);
    }
  }, [existingSelectedAlergies]);

  useEffect(() => {
    const finalAlergies = (includeSelectedInAlergies && selected.length) ? `${alergies}\nAlergies: ${selected.join(', ')}` : alergies;
    onAlergiesChange(finalAlergies);
  }, [alergies, selected.length]);

  useEffect(() => {
    onSelectedAlergiesChange(selected);
  }, [selected]);

  return (
    <div className={classes.root}>
      <span>
        {t('alergies.note')}
      </span>
      <div className={classes.container}>
        <FormGroup className={classesContainer.verticalContainer}>
          {predefinedAlergies.map((alergie) => (
            <FormControlLabel
              key={alergie}
              control={<Checkbox
                checked={selected.includes(alergie)}
                onChange={e => {
                  const checked = e.target.checked;
                  if(checked) setSelected(prev => [...prev, alergie]);
                  else setSelected(prev => prev.filter(a => a !== alergie));
                }}
              />}
              label={alergie}
            />
          ))}
          <FormControlLabel
            control={<Checkbox />}
            label={t("alergies.other")}
          />
        </FormGroup>
        <TextAreaInput
          placeholder={t('alergies.placeholder')}
          value={(includeSelectedInAlergies && selected.length) ? `${alergies}\nAlergies: ${selected.join(', ')}` : alergies}
          onChange={v => {
            const value = v.replace(`\nAlergies: ${selected.join(', ')}`, '')
            setAlergies(value)
          }}
        />
      </div>
    </div>
  );
};
