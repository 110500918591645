import * as Yup from "yup";
import { FC, useCallback, useEffect, useState } from "react";
import { TextInput } from "../booking/shared/components/TextInput";
import { ColoredText } from "../booking/shared/components/ColoredText";
import { useInputStyles } from "../booking/complex/styles";
import { useDetailsStyles } from "./styles";
import { debounced } from "../booking/shared/utils";
import { useTranslation } from "react-i18next";

type Props = {
  label?: string;
  onChange?: (v: any, valid: boolean) => any;
  mobile?: string;
};

export const ParentFormGroup: FC<Props> = ({
  label,
  onChange = () => {},
  mobile: existingMobile
}) => {
  const { t } = useTranslation('details');

  const classes = useDetailsStyles();
  const classesInput = useInputStyles();

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState(existingMobile ?? "");
  const [email, setEmail] = useState("");
  
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [mobileErrorMessage, setMobileErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const validation = Yup.object().shape({
    name: Yup.string().required(t('parent.errors.name')),
    mobile: Yup.string()
      .required(t('parent.errors.phoneNumber.required'))
      .matches(/^[0-9+]{8,13}$/, { message: t('parent.errors.phoneNumber.invalid') }),
    email: Yup.string().required(t('parent.errors.email.required'))
      .matches(
      /(^$)|(^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/,
      { message: t('parent.errors.email.invalid') }
    ),
  });

  const debouncedChange = useCallback(debounced(function (name: string, mobile: string, email: string) {
    const v = {
      name, mobile, email
    };
    onChange(v, validation.isValidSync(v));
    validation.validateAt('name', v)
      .then(() => setNameErrorMessage(""))
      .catch(e => setNameErrorMessage(e.message.toString()));
    validation.validateAt('mobile', v)
      .then(() => setMobileErrorMessage(""))
      .catch(e => setMobileErrorMessage(e.message));
    validation.validateAt('email', v)
      .then(() => setEmailErrorMessage(""))
      .catch(e => setEmailErrorMessage(e.message));
  }, 700), []) as any;

  useEffect(() => {
    debouncedChange(name, mobile, email);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, mobile, email]);
  const v = {
    name, mobile, email
  };

  return (
    <>
      <div className={classes.paddingContainer}>
        <ColoredText>{label}</ColoredText>
      </div>
      <div className={classes.marginContainer}>
        <div className={classesInput.fieldContainer}>
          <TextInput
            value={name}
            onChange={e => setName(e.target.value)}
            label={'*' + t("parent.nameAndSurnameLabel")}
            className={classesInput.unsetMaxWidth}
            
          />
          <span className={classesInput.errorMessage}>{nameErrorMessage}</span>
        </div>
        <div className={classesInput.fieldContainer}>
          <TextInput
            value={mobile}
            onChange={e => setMobile(e.target.value)}
            label={'*' + t("parent.phoneNumberLabel")}
            className={classesInput.unsetMaxWidth}
            
          />
          <span className={classesInput.errorMessage}>{mobileErrorMessage}</span>
        </div>
        <div className={classesInput.fieldContainer}>
          <TextInput
            value={email}
            onChange={e => setEmail(e.target.value)}
            label={'*' + t("parent.emailLabel")}
            className={classesInput.unsetMaxWidth}
            
          />
          <span className={classesInput.errorMessage}>{emailErrorMessage}</span>
        </div>
      </div>
    </>
  );
};
