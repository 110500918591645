import { Card, CardContent, CardHeader, Grid, IconButton } from "@mui/material";
import React, { FC, useContext } from "react";
import { ReservationContext } from "../shared/state";
import { calcCount, calcTotalPrice, ComplexBookingContext } from "./state";
import { Badge } from "../shared/components/Badge";
import { Line } from "../shared/components/Line";
import { TextButton } from "../shared/components/TextButton";
import { useStatePreviewStyles } from "./styles";
import { ColoredText } from "../shared/components/ColoredText";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { DetailsContext } from "../../details/state";
import { scrollableContainerId, useStepLabels } from "./Steps";
import { useTranslation } from "react-i18next";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import { CartIconWithBubble } from "./PageTitle";
import { TotalPrice, TotalPriceDisplay } from "./TotalPrice";

type Props = {
  onClose?: () => any;
};

export const StatePreview: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation("group-booking");

  const { StepLabelRegistry } = useStepLabels();

  const classes = useStatePreviewStyles();

  const { state: reservationState } = useContext(ReservationContext);
  const { state: complexState, dispatch: dispatchComplex } = useContext(
    ComplexBookingContext
  );
  const { state: detailsState } = useContext(DetailsContext);

  const setStep = (s: number) => {
    dispatchComplex({ type: "SET_STEP", data: s });
    // timeout required in order to wait for an accordion toggle to complete
    setTimeout(() => {
      const scrollToElement = document.getElementById(`step_${s}`);
      const containerElement = document.getElementById(scrollableContainerId);
      if(containerElement && scrollToElement)
        containerElement.scrollTo({
          behavior: "smooth",
          top: scrollToElement.offsetTop,
        });
    }, 300);
  };

  const stepComponentRegistry: { [key: number]: JSX.Element } = {
    0: (
      <>
        {complexState.activities.map((item, i) => {
          const price =  reservationState?.activity?.id === item.id ? reservationState?.priceInfo?.price 
          : complexState.useMainActivityCostOnly ? ""
          : item.getPriceInfoForSubActivity(moment(reservationState.date))?.price;
          return (
            <div key={i}>{item?.name} {price ? `- ${price} kr.` : ''}</div>
          )
        }
        )}
        {complexState.defaultSubActivities.map((item, i) => {
          const price =  reservationState?.activity?.id === item.id ? reservationState?.priceInfo?.price 
          : complexState.useMainActivityCostOnly ? ""
          : item.getPriceInfoForSubActivity(moment(reservationState.date))?.price;
          return (
            <div key={i}>{item?.name} {price ? `- ${price} kr.` : ''}</div>
          )
        }
        )}
      </>
    ),
    1: (
      <>
        <div>
          {moment(reservationState.date).format("DD.MM.yyyy.")} |{" "}
          {reservationState.timeSlot &&
            reservationState.timeSlot.start().format("HH:mm")}{" "}
          -{" "}
          {reservationState.timeSlot &&
            reservationState.timeSlot.end().format("HH:mm")}
        </div>
        <div>
          {calcCount(reservationState,complexState)??0} {t("summary.people")}
        </div>
      </>
    ),
    2: (
      <>
        {complexState.selectedItems.map((item, i) => (
          <React.Fragment key={item.name + i}>
            <div>
              {item.name} - {item.price} kr.
            </div>
            <br />
          </React.Fragment>
        ))}
        {complexState.alergies && <div>“{complexState.alergies}”</div>}
        {!!complexState.selectedAlergies?.length && (
          <div>{t("summary.alergies")}:</div>
        )}
        {complexState.selectedAlergies?.map((a) => (
          <div key={a}>- {a}</div>
        ))}
      </>
    ),
    3: (
      <>
        <div>{t("summary.parent")}</div>
        <div>
          {detailsState.contactPerson.firstname}{" "}
          {detailsState.contactPerson.lastname}
        </div>
        <div>{detailsState.mobile}</div>
        <div>{detailsState.email}</div>
        {(detailsState.children.length > 0) && <div>{t("summary.children")}</div>}
        {detailsState.children.map((child, i) => (
          <React.Fragment key={i}>
            <div>{child.name}</div>
            {child.age && (
              <div>
                {child.age} {t("summary.years")}
              </div>
            )}
          </React.Fragment>
        ))}
      </>
    ),
  };
  
  return (
    <Card className={classes.bookingCard}>
      <CardHeader
        title={
          <div className={classes.cardHeaderContainer}>
            <div>
              <CartIconWithBubble
                CartIcon={<ShoppingCart />}
                top="8px"
                right="-10px"
              />{" "}
              {t("summary.cardTitle")}
            </div>
            {onClose ? (
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </div>
        }
        className={classes.cardHeader}
      />
      <CardContent>
        <div className={classes.summaryGridContainer}>
          {Array.from({ length: 4 }, (_, i) => i).map((s) => {
            const disabled = false; // s === 0;
            return (
              <React.Fragment key={s}>
                {s !== 0 && <hr style={{ margin: '-8px 0 8px 0' }} />}
                <Grid container>
                  <Grid item xs={1} className={classes.numbersGridContainer}>
                    <Badge>{s + 1}</Badge>
                  </Grid>
                  <Grid item xs={5} className={classes.namesGridContainer}>
                    <b>{StepLabelRegistry[s]}</b>
                    <br />
                    <TextButton onClick={() => setStep(s)} >
                      {disabled
                        ? t("summary.viewButton")
                        : t("summary.changeButton")}
                    </TextButton>
                  </Grid>
                  <Grid item xs={6} className={classes.contentGridContainer}>
                    {stepComponentRegistry[s]}
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
        </div>
        <Line />
        <div className={classes.totalPrice}>
          <TotalPriceDisplay/>
        </div>
      </CardContent>
    </Card>
  );
};
