import { TextField, TextFieldProps } from "@mui/material";
import { FC } from "react";
import { useInputStyles } from "../../complex/styles";

export const TextInput: FC<TextFieldProps> = (props) => {
  const classes = useInputStyles();

  return (
    <div className={classes.inputContainer}>
      <label>{props.label}</label>
      <TextField
        {...props}
        label={undefined}
        variant="standard"
        className={`${classes.field} ${props.className}`}
        InputProps={{ disableUnderline: true }}
      />
    </div>
  );
};
