/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { DetailsContext } from "../../details/state";
import { Line } from "../shared/components/Line";
import { TextBadge } from "../shared/components/TextBadge";
import { TextButton } from "../shared/components/TextButton";
import { ReservationContext } from "../shared/state";
import { calcUnitPrice, ComplexBookingContext, getNeedsLongerDuration, getSelectedCoversDuration, getSelectedDuration, N_OF_STEPS} from "./state";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAccordionStyles, useContainerStyles } from "./styles";
import { ColoredText } from "../shared/components/ColoredText";
import { DetailsForm } from "../../details/DetailsForm";
import { Confirmation } from "./Confirmation";
import LockIcon from "@mui/icons-material/Lock";
import { debounced } from "../shared/utils";
import { DateTimeAtendees } from "./DateTimeAtendees";
import moment from "moment";
import { Activities } from "./Activities";
import { Catalog } from "./Catalog";
import { useTranslation } from "react-i18next";
import Loading from "../../shared/components/Loading";
import { getMaxNumberOfActivitiesFor } from "../services/timeSlotUtils";
import { useStyles } from "../shared/styles";
import { useLocation } from "react-router-dom";
import { Guid } from "../../system/guid";
import { UnitPrice } from "./UnitPrice";
import { TotalPriceStatusDisplay } from "./TotalPrice";

type Props = {};

export const scrollableContainerId = "scrollableStepsContainer";

export const useStepLabels = () => {
  const { t } = useTranslation("group-booking");

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const packageDuration = query.get("packageDuration") || "";
  const parsedPackageDuration = parseInt(packageDuration);

  const { state: complexState } = useContext(ComplexBookingContext);
  const { state: reservationState } = useContext(ReservationContext);
  
  const StepLabelRegistry: { [key: number]: JSX.Element } = {
    0: !!packageDuration ? (<>{t("steps.activities")} {(parsedPackageDuration ?? 0) / 60} {t('steps.hours')}, <UnitPrice/></>)
     : (<>{t('steps.selectedActivities')}</>),
    1: (<>{t("steps.dateTimeAtendees")}</>),
    2: (<>{t("steps.foodDrinks")}</>),
    3: (<>{t("steps.consent")}</>),
    4: (<>{t("steps.confirmation")}</>),
  };

  return { StepLabelRegistry };
};

export const Steps: FC<Props> = () => {
  const { t } = useTranslation("group-booking");
  const { StepLabelRegistry } = useStepLabels();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isPackage = query.getAll("activityId")?.length;

  const classes = useAccordionStyles();
  const classesContainer = useContainerStyles();
  const classesShared = useStyles();

  const { state: reservationState } = useContext(ReservationContext);
  const { state: complexState, dispatch: dispatchComplex } = useContext(
    ComplexBookingContext
  );
  const { state: detailsState, dispatch: dispatchDetails } =
    useContext(DetailsContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [_, setScrolledToBottom] = useState(false);

  useEffect(() => {
    if (isMobile) {
      const containerElement = document.getElementById(scrollableContainerId);
      const listener = debounced(() => {
        const scrollPos = containerElement?.scrollTop ?? 0;
        const offsetHeight = containerElement?.offsetHeight ?? 0;
        const scrollHeight = containerElement?.scrollHeight ?? 0;
        if (offsetHeight + scrollPos >= scrollHeight) {
          setScrolledToBottom(true);
        } else {
          setScrolledToBottom(false);
        }
      }, 100);
      containerElement?.addEventListener("scroll", listener);

      return () => {
        containerElement?.removeEventListener("scroll", listener);
      };
    }
  }, [isMobile]);

  const { step, expanded } = complexState;

  const setStep = (s: number) => {
    dispatchComplex({ type: "SET_STEP", data: s });
  };

  const toggle = (s: number) => {
    dispatchComplex({ type: "TOGGLE_STEP", data: s });
  };

  const close = (s: number) => {
    dispatchComplex({ type: "CLOSE_STEP", data: s });
  };

  const needsLongerDuration = getNeedsLongerDuration(reservationState,complexState);
  const hasDefaultSubActivity = reservationState.hasDefaultSubActivity;
  const selectedDuration = getSelectedDuration(reservationState,complexState);
  const needsMoreActiveties = !hasDefaultSubActivity && (reservationState.priceInfo?.duration??0) > selectedDuration;

  const stepComponentRegistry: { [key: number]: JSX.Element } = {
    0: (
      <>
        <div className={classesContainer.wrappedContainer}>
          <Activities />
          
        </div>
      </>
    ),
    1: (
      <div className={classesContainer.wrappedContainer}>
        <DateTimeAtendees
          activities={complexState.activities}
          needsLongerDuration={needsLongerDuration}
          needsMoreActiveties={needsMoreActiveties}
        />
      </div>
    ),
    2: (
      <div className={classesContainer.wrappedContainer}>
        <Catalog />
      </div>
    ),
    3: (
      <div className={classesContainer.wrappedContainer}>
        <DetailsForm
          includeChildren={reservationState.activity?.isBirthday ?? false}
          onSubmit={(values, valid) => {
            dispatchDetails({
              type: "SET_DETAILS",
              data: values,
            });
            dispatchDetails({
              type: "SET_VALID",
              data: valid,
            });
          }}
        />
      </div>
    ),
    4: (
      <div className={classesContainer.wrappedContainer}>
        <Confirmation />
      </div>
    ),
  };

  const stepSummaryRegistry: { [key: number]: JSX.Element } = {
    0: isPackage ? (
      <span>
        {t("summary.customPackage.note")}{" "}
        <ColoredText
          className={classesShared.clickable}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = `/booking/${reservationState.activity?.id}`;
          }}
        >
          {t("summary.customPackage.linkLabel")}
        </ColoredText>
        .
      </span>
    ) : (
      <Typography>
          {hasDefaultSubActivity? "Minimum tid ":"Valgt tid "}
          {selectedDuration > 30? moment.utc(moment.duration(selectedDuration, "minutes").asMilliseconds()).format("h:mm") + " timer": `${selectedDuration} minutter`}
      </Typography>
    ),
    1: (<>
        <span>
          {moment(reservationState.date).format("DD.MM.yyyy.")} |{" "}
          {reservationState.timeSlot &&
            reservationState.timeSlot.start().format("HH:mm")}{" "}
          -{" "}
          {reservationState.timeSlot &&
            reservationState.timeSlot.end().format("HH:mm")}{" "}
          {reservationState.count ?? 0} {t("common.people")}
        </span>
        <TotalPriceStatusDisplay/>
      </>
    ),
    2: <TotalPriceStatusDisplay/>,
    3: <TotalPriceStatusDisplay/>,
    4: <TotalPriceStatusDisplay/>,
  };

  const ProceedButton = (s: number, enabled: boolean = true) => (
    <TextButton
      onClick={() => {
        close(s);
        setStep(s + 1);
      }}
      disabled={!enabled}
      variant="contained"
    >
      {t("common.proceed")}
    </TextButton>
  );

  const stepButtonRegistry: { [key: number]: JSX.Element } = {
    0: ProceedButton(0),
    1: ProceedButton(1,!needsMoreActiveties && reservationState.isValid),
    2: ProceedButton(2),
    3: ProceedButton(3, detailsState.isValid && !needsMoreActiveties && reservationState.isValid),
    4: (
      <TextButton
        onClick={() => {
          dispatchComplex({ type: "MAKE_RESERVATION", data: {} });
        }}
        disabled={
          !complexState.code ||
          complexState.makeReservation ||
          needsLongerDuration ||
          !detailsState.isValid ||
          needsMoreActiveties
        }
        variant="contained"
      >
        {complexState.makeReservation ? (
          <Loading size={30} sx={{ color: "#fff" }} />
        ) : (
          t("common.finish")
        )}
      </TextButton>
    ),
  };

  return (
    <div className={classes.root} id={scrollableContainerId}>
      {
        /* !scrolledToBottom && */ isMobile && (
          <div
            className="ca3-scroll-down-link ca3-scroll-down-arrow"
            onClick={() => {
              const e = document.getElementById(scrollableContainerId);
              e?.scrollTo({
                behavior: "smooth",
                top: e.scrollHeight,
              });
            }}
          ></div>
        )
      }
      {Array.from({ length: N_OF_STEPS }, (_, i) => i).map((s) => {
        const disabled = false; //s === 0;
        return (
          <Accordion
            id={`step_${s}`}
            key={s}
            className={classes.accordion}
            expanded={step === s || expanded[s]}
            onChange={() => toggle(s)}
          >
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            >
              <TextBadge>{s + 1}</TextBadge>
              <Typography className={classes.accordionTitle}>
                {StepLabelRegistry[s]}
              </Typography>
              {disabled && (
                <Tooltip
                  title={t("common.locked")}
                  className={classes.lockIcon}
                >
                  <LockIcon />
                </Tooltip>
              )}
            </AccordionSummary>
            <AccordionDetails className={`${classes.accordionDetails}`}>
              <div className={`${disabled ? classes.accordionDisabled : ""}`}>
                {stepComponentRegistry[s]}
                <Line />
              </div>
              <div className={classes.accordionDetailsSummary}>
                {stepSummaryRegistry[s]}
                {stepButtonRegistry[s]}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
