import { Route } from "react-router-dom";
import { Payment } from "./Payment";
import { PaymentReceipt } from "./PaymentReceipt";
import { useStyles } from "./styles";

export const PaymentRoutes = () => {
  const classes = useStyles();

  return (
    <>
      <Route exact path="/:lang/payment/"><Payment /></Route>
      <Route exact path="/:lang/payment/receipt/:orderId"><PaymentReceipt /></Route>
      <div className={classes.backgroundImage}></div>
    </>
  );
}