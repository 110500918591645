import {
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";

import { useParams } from "react-router-dom";
import { useResource } from "../shared/hooks/useResource";
import { useContext } from "react";
import { DetailsContext } from "../details/state";
import { DetailsForm } from "../details/DetailsForm";
import { useStatePreviewStyles } from "../booking/complex/styles";
import { WaiverInfoComponent } from "./WaiverInfoComponent";
import { useTranslation } from "react-i18next";
import { useNavigate } from "../booking/shared/hooks";

export const WaiverDetails = () => {
  const { t } = useTranslation("waiver");

  const classes = useStatePreviewStyles();

  const navigate = useNavigate();

  const { state, dispatch } = useContext(DetailsContext);

  const { refId } = useParams<{ refId?: string }>();
  const { resource } = useResource(refId);

  const handleSubmit = (values: any) => {
    dispatch({
      type: 'SET_DETAILS',
      data: values
    });
  }

  return (
    <>
      <Card className={classes.bookingCard} style={{width: 'unset'}}>
        <CardHeader
          className={classes.cardHeader}
          title={`${t('title')} ${resource?.name}`}
        />
        <CardContent>
          <DetailsForm
            onSubmit={handleSubmit}
            showCheckboxes={false}
            mobile={state.mobile}
          />
          <WaiverInfoComponent
            resource={resource}
            onSuccess={() => navigate(`/egenerklering/${resource?.prittyUrl}/confirmed`)}
            state={state}
            setMobile={(mobile: string) => {
              dispatch({ type: "SET_MOBILE", data: mobile });
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};
