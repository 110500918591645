import { FC } from "react";
import { useLineStyles } from "../../complex/styles";

type Props = {

}

export const Line: FC<Props> = () => {
  const classes = useLineStyles();

  return (
    <hr className={classes.root} />
  )
}