import CakeIcon from "@mui/icons-material/Cake";
import { useLocation, useParams } from "react-router-dom";
import { FC, useContext, useEffect, useState } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { PageTitle } from "./PageTitle";
import { useComplexStyles } from "./styles";
import { Steps } from "./Steps";
import { ResponsiveStatePreview } from "./ResponsiveStatePreview";
import { ReservationContext } from "../shared/state";
import { useActivity } from "../activity/useActivity";
import { Backdrop, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useStyles } from "../shared/styles";
import { useTranslation } from "react-i18next";
import { ComplexBookingContext } from "./state";
import Loading from "../../shared/components/Loading";
import { ColoredText } from "../shared/components/ColoredText";

type Props = {};

export const ComplexBooking: FC<Props> = () => {
  const { t } = useTranslation('group-booking');

  const classesComplex = useComplexStyles();
  const classesShared = useStyles();
  
  const { dispatch: dispatchReservation } = useContext(ReservationContext);
  const { state,dispatch:dispatchComplex } = useContext(ComplexBookingContext);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isPackage = query.getAll("activityId")?.length;
  
  const { mainActivityId } = useParams<{ mainActivityId?: string }>();
  const { activity } = useActivity(mainActivityId ?? "a547b077-7345-42c6-8f6f-66f90b4eebef");
  
  useEffect(() => {
    dispatchReservation({ type: "SET_ACTIVITY", data: activity });
    dispatchComplex({ type: "SET_ACTIVITY", data: activity });
  }, [activity, dispatchReservation]);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <PageTitle
        resourceName={activity?.site?.name ?? ''}
        activityName={activity?.name ?? ''}
        onOpenPreview={() => setDialogOpen(true)}
        Icon={<CakeIcon />}
        CartIcon={<ShoppingCartIcon />}
      />
      {!isSM && <div className={classesShared.descriptionContainer}>
        <Typography>
          {t("subtitle")}
        </Typography>
        {isPackage && <Typography marginTop="1rem">
          {t("summary.customPackage.note")}{" "}
          <ColoredText
          className={classesShared.clickable}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = `/booking/${mainActivityId}`;
          }}>
            {t("summary.customPackage.linkLabel")}
          </ColoredText>
          .
        </Typography>}
      </div>}
      <div className={classesComplex.root}>
        <Steps />
        <ResponsiveStatePreview
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
        />
      </div>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={state.isLoadingActivities || state.isLoadingProducts || !activity}
      >
        <Loading />
      </Backdrop>
    </>
  );
};
