import { makeStyles } from "@mui/styles";
import * as React from "react";
import { ColoredText } from "../../booking/shared/components/ColoredText";

const useStyles = makeStyles({
  root: {},
});

type props = {
  text?: string;
  children?: JSX.Element;
};

export const PolicyLink: React.FC<props> = ({ text, children }) => {
  text = text || "";
  const classes = useStyles();
  return (
    <a
      target="top"
      href="https://www.skyland.no/no/kristiansand/praktisk-info/personvern"
    >
      {children || <ColoredText>{text}</ColoredText>}
    </a>
  );
};

export const TermsLink: React.FC<props> = ({ text, children }) => {
  const classes = useStyles();
  return (
    <a
      target="top"
      href="https://www.skyland.no/no/arendal/praktisk-info/vilkaar-betingelser/"
    >
      {children || <ColoredText>{text}</ColoredText>}
    </a>
  );
};
