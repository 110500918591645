import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LngDetector from "i18next-browser-languagedetector";

export const SUPPORTED_LANGUAGES = ["en", "no"];

const detectionOptions = {
  order: ['path', 'cookie', 'navigator', 'localStorage', 'subdomain', 'queryString', 'htmlTag'],
  lookupFromPathIndex: 0
}

i18next
  .use(initReactI18next)
  .use(LngDetector)
  .use(Backend)
  .init({
    debug: true, // TODO: Set to false in production,
    fallbackLng: 'en',
    detection: detectionOptions
  });