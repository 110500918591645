import { FC } from "react";
import { useBadgeStyles } from "../../complex/styles";
import { Badge } from "./Badge";

type Props = {

}

export const TextBadge: FC<Props> = ({ children }) => {
  const classes = useBadgeStyles({});

  return (
    <Badge>
      <div className={classes.square}>{children}</div>
    </Badge>
  )
}