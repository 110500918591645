import { Redirect, Route } from "react-router-dom";
import { MaintenanceHandler } from "@bitmesh/maintenance";
import { FILE_API_URL } from "../environment";
import { StartPage } from "../startpage/StartPage";
import { WaiverRoutes } from "../waiver/WaiverRoutes";
import { SimpleBookingRoutes } from "../booking/simple/SimpleBookingRoutes";
import { ComplexBookingRoutes } from "../booking/complex/ComplexBookingRoutes";
import { PaymentRoutes } from "../payment/PaymentRoutes";
import { ActivityBookingRoutes } from "../booking/activity/ActivityBookingRoutes";
import { RefNumberRoutes } from "../ref-number/RefNumberRoutes";
import { SUPPORTED_LANGUAGES } from "../i18n";

export const AppRoutes = () => {
  return (
    <>
      <MaintenanceHandler
        fileApiUrl={FILE_API_URL}
        userId={"public-user"}
        appId="bitmesh.skyland.app"
        tokenProvider={(url: string) => {
          return new Promise((resolve, reject) => {
            resolve("");
          });
        }}
      />
      <Route exact path="/">
        <StartPage />
      </Route>
      <Route exact path="/:lang/egenerklering*">
        <WaiverRoutes />
      </Route>
      {/* <Route exact path="/:lang/book*">
        <SimpleBookingRoutes />
      </Route> */}
      <Route exact path="/:lang/activetybook*">
        <ActivityBookingRoutes />
      </Route>
      {/* <Route exact path="/:lang/vrbook*">
        <SimpleBookingRoutes />
      </Route> */}
      <Route exact path="/:lang/booking*">
        <ComplexBookingRoutes />
      </Route>
      <Route exact path="/:lang/payment*">
        <PaymentRoutes />
      </Route>
      <Route exact path="/:lang/ref-number*">
        <RefNumberRoutes />
      </Route>
      <Route
        path="/:lang/"
        render={(props) => {
          return SUPPORTED_LANGUAGES.includes(props.match.params.lang) ? (
            <></>
          ) : (
            <Redirect to={`/no${props.location.pathname}${props.location.search}`} />
          );
        }}
      />
    </>
  );
};
