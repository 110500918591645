import { Route } from "react-router-dom";
import { ActivityConfirmBookingComponent } from "./ActivityConfirmBookingComponent";
import { ActivityBooking } from "./ActivityBooking";
import { useReservationReducer, ReservationContext } from "../shared/state";
import { useSimpleStyles } from "./styles";

// const HoppetimeSubtitle = "Velkommen til booking hos Skyland. En ny spennende opplevelse for hele familien! Det spiller ingen rolle om du er nybegynner eller profesjonell, liten eller stor, her har du muligheten til å møte spennende utfordringer i et sosialt, pedagogisk og aktivt miljø på Skyland Trampolinepark. Fyll inn informasjonen under for å sikre deg hoppetid hos oss.";
// const VrSubtitle = "Vex-arena er en virituell verden, hvor man gjennom syv forskjellige modus kan kjempe mot / med andre medspillere. Vår arena tillater totalt 6 spillere samtidig. De syv forskjellige modusene variere fra alle mot en, til lagspill. De mest kjente spillformatene er Deathmatch, Archery, King of the hill, Battle Royale og lagvariasjoner av disse.";


export const ActivityBookingRoutes = () => {
  const classesSimple = useSimpleStyles();

  const [state, dispatch] = useReservationReducer();

  return (
    <>
      <ReservationContext.Provider value={{ state, dispatch }}>
        <Route exact path="/:lang/activetybook/:mainActivityId">
          <ActivityBooking />
        </Route>
        <Route exact path="/:lang/activetybook/confirm/:mainActivityId">
          <ActivityConfirmBookingComponent
            bookButtonLabel="Book hoppetid"
          />
        </Route>
        
      </ReservationContext.Provider>
      <Route exact path="/:lang/activetybook/*">
        <div className={`${classesSimple.hoppetimeImage}`}></div>
      </Route>
    </>
  );
}