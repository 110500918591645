import { FC } from "react";
import { useButtonStyles } from "../../complex/styles";

type Props = {
  onClick?: () => any;
}

export const DarkButton: FC<Props> = ({ onClick = () => {}, children }) => {
  const classes = useButtonStyles();

  return (
    <button className={classes.darkButton} onClick={e => {
      onClick();
    }}>
      {children}
    </button>
  )
}