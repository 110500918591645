import { Observable, map } from 'rxjs';
import { Product, ProductItem } from '../model/product';
import { ObservableAxios } from '../../system/rxjs-axios';
import { SCHEDULING_API_URL } from '../../environment';

export class CatalogService {

  private readonly catalogApi = `${SCHEDULING_API_URL}/api/products`; // 'http://localhost/api/products' // CATALOG_API_URL + '/umbraco/api/product/get';

  constructor(private http: ObservableAxios) { }

  public getCatalog(resourceUrl?: string): Observable<Array<Product>> {
    return this.fetchCatalog();
    
  }

  private fetchCatalog(): Observable<Array<Product>>{
    return this.http.get(this.catalogApi).pipe(map((data:Array<any>) => {
        return data.map(Product.deSerialize);
      }
    ));
  }
}
