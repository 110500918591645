export enum PaymentStatus {
  Initiate = 0,
  Reserved = 1,
  CancelledByUser = -1,
  Error = -2,
  Timeout = -3,
  Captured = 10
}

export function isPaid(paymentStatus: PaymentStatus): boolean {
  return (
    paymentStatus === PaymentStatus.Reserved ||
    paymentStatus === PaymentStatus.Captured
  );
}