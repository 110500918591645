import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTimeScheduleService } from "../booking/services/time-schedule.service";
import { AlergiesField } from "../booking/complex/AlergiesField";
import { useStyles } from "./styles";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "../booking/shared/hooks";
import { SelectInput } from "../booking/shared/components/SelectInput";
import PeopleRounded from "@mui/icons-material/PeopleRounded";

type Props = {
  refCode: string;
  reservationId: string;
};
const maxAtendees = 30;
const minAtendees = 5;

export const PartialUpdate: FC<Props> = ({ refCode, reservationId }) => {
  const { t } = useTranslation("ref-number");
  const { t: td } = useTranslation('date-time-atendees');

  const classes = useStyles();

  const navigate = useNavigate();

  const { timeScheduleService } = useTimeScheduleService();

  const [alergies, setAlergies] = useState("");
  const [count, setCount] = useState(0);
  const [updateErrorMessage, setUpdateErrorMessage] = useState<string>();

  useEffect(() => {
    reservationId && timeScheduleService.getGroupReservationById(reservationId)
      .subscribe({
        next: r => {
          setAlergies(r?.message ?? "");
          setCount(r?.reservation?.count ?? 0);
        },
        error: console.error
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId]);

  const updateAlergies = () => {
    reservationId && timeScheduleService.updateGroupReservationPartial(reservationId, { alergies, count })
      .subscribe({
        next: () => navigate(`/ref-number/${refCode}`),
        error: (e) => {
          console.error(e)
          setUpdateErrorMessage(t('alergies.updateError'));
        }
      })
  }

  return (
    <>
      <div className={classes.alergiesContainer}>
        {/* <Typography className={classes.subtitleText} sx={{ marginTop: '0 !important' }}>
          {t("participants.title")}
        </Typography> */}
        {/* TODO: Implement this */}
        {/* <div style={{ marginBottom: '1em' }}>
          <SelectInput
            label={
              <>
                *{td("labels.atendees")} <PeopleRounded />
              </>
            }
            value={count ?? ""}
            onChange={c => setCount(c as number)}
            options={Array.from({ length: maxAtendees - minAtendees + 1 }, (_, i) => ({
              label: i + minAtendees!,
              value: i + minAtendees!,
            }))}
          />
        </div> */}
        <Typography className={classes.subtitleText} sx={{ marginTop: '0 !important' }}>
          {t("alergies.title")}
        </Typography>
        <AlergiesField
          alergies={alergies}
          includeSelectedInAlergies={true}
          onAlergiesChange={alergies => setAlergies(alergies)}
        />
        {updateErrorMessage && (
          <Typography color="red">{updateErrorMessage}</Typography>
        )}
        <Button
          className={classes.verifyButton}
          variant="contained"
          onClick={() => updateAlergies()}
        >
          {t("alergies.button")}
        </Button>
      </div>
    </>
  );
};
