import { PaymentStatus } from "./payment-status";
import moment from "moment";

export class ReceiptDataVipps {
  constructor(
    public status: PaymentStatus,
    public referenceNumber: string,
    public startTime: moment.Moment|null
  ) {}

  static deSerialize(json: any): ReceiptDataVipps {
    const startTime = json.startTime ? moment(json.startTime) : null;

    return new ReceiptDataVipps(json.status, json.referenceNumber, startTime);
  }

  public isCallFinalizeForReceiptData(): boolean {
    return this.status === PaymentStatus.Initiate;
  }
}
