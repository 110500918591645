import { IconButton, Typography, Badge as MuiBadge } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "../shared/components/Badge";
import { ReservationContext } from "../shared/state";
import { ComplexBookingContext } from "./state";
import { usePageTitleStyles } from "./styles";

type BubbleProps = {
  title?: string;
  CartIcon?: JSX.Element;
  Wrapper?: FC;
  top?: string;
  right?: string;
};

export const CartIconWithBubble: FC<BubbleProps> = ({
  Wrapper,
  CartIcon,
  title = "",
  top = "5px",
  right = "5px"
}) => {
  const classes = usePageTitleStyles();
  
  Wrapper = Wrapper || (({ children }) => <>{children}</>);

  const { state: complexState } = useContext(ComplexBookingContext);

  return (
    <Wrapper>
      <Badge>
        <MuiBadge
          badgeContent={
            complexState.selectedItems.length + complexState.activities.length
          }
          color="primary"
          overlap="rectangular"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          componentsProps={{
            badge: {
              style: {
                backgroundColor: "transparent",
                color: "#fff",
                borderRadius: "40%",
                fontSize: "14px",
                top,
                right,
              },
            },
          }}
        >
          {CartIcon}
        </MuiBadge>
        {title}
      </Badge>
    </Wrapper>
  );
};

type Props = {
  resourceName?: string;
  activityName?: string;
  onOpenPreview?: () => any;
  Icon?: JSX.Element;
  CartIcon?: JSX.Element;
};

export const PageTitle: FC<Props> = ({
  resourceName = "",
  activityName = "",
  Icon,
  CartIcon,
  onOpenPreview = () => {},
}) => {
  const { state: reservationState } = useContext(ReservationContext);

  const classes = usePageTitleStyles();

  const { t } = useTranslation("group-booking");

  return (
    <div
      className={classes.root}
      style={{ justifyContent: CartIcon ? "" : "center" }}
    >
      {reservationState.activity?.isBirthday && <Badge>{Icon}</Badge>}
      <Typography>
        Booking {activityName} i {resourceName}
      </Typography>
      {CartIcon && (
        <CartIconWithBubble
          title={t("summary.cardTitle")}
          CartIcon={CartIcon}
          Wrapper={({ children }) => (
            <IconButton
              className={`${classes.bigScreenHidden} ${classes.previewButton}`}
              onClick={onOpenPreview}
            >
              {children}
            </IconButton>
          )}
        />
      )}
    </div>
  );
};
