import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import BgHoppetimeBooking from "../../assets/bookingbilde.png";
import BgVrBooking from "../../assets/bookingvr.jpg";

export const useSimpleStyles = makeStyles((theme: Theme) =>
  createStyles({
    vrBackgroundImage: {
      backgroundImage: `url("${BgVrBooking}")`,
    },
    hoppetimeImage: {
      backgroundImage: `url("${BgHoppetimeBooking}")`,
    },

    descriptionContainer: {
      margin: "1em 1em 2em 1em",
      fontSize: "14px",
    },
  })
);

export const useActivityBookingStyles = makeStyles((theme: Theme) =>
  createStyles({
    bookingFormContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "stretch",
      padding: "1em",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
        flexDirection: "column",
      },
    },
    imageContainer: {
      "& > img": {
        maxHeight: "250px",
        maxWidth: "250px",
        objectFit: "fill",
        borderRadius: "10px",
      },
    },
    dateTimeAttendeesContainer: {
      alignItems: "center",
      paddingLeft: "2em",
    },
  })
);

export const useActivityPreviewStyles = makeStyles((theme: Theme) =>
  createStyles({
    note: {
      marginTop: '2em',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderRadius: theme.border?.radius,
      backgroundColor: 'black',
      padding: '1.5em',
      fontSize: '14px',
      '& > svg': {
        color: theme.palette.primary.main
      },
      '& > span': {
        marginLeft: '1.5em'
      }
    },
  })
);
