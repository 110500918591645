import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useDetailsStyles = makeStyles((theme: Theme) =>
  createStyles({
    paddingContainer: {
      padding: '0.2em 0.7em'
    },
    marginContainer: {
      margin: '0 0 1em 0',
      display: 'flex',
      flexWrap: 'wrap'
    },
    smallerText: {
      fontSize: '14px'
    }
  })
);
