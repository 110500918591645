import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { FC, useContext, useEffect, useState } from "react";
import { CatalogService } from "../../shared/services/catalog.service";
import { Product } from "../../shared/model/product";
import { observableAxios } from "../../system/rxjs-axios";
import { ComplexBookingContext } from "./state";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loading from "../../shared/components/Loading";
import { useAccordionStyles, useContainerStyles } from "./styles";
import { DarkButton } from "../shared/components/DarkButton";
import { Alergies } from "./Alergies";
import { SelectableImage } from "../shared/components/SelectableImage";
import { FILE_API_URL } from "../../environment";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

type Props = {
  resourceUrl?: string;
};

export const Catalog: FC<Props> = ({ resourceUrl }) => {
  const { t } = useTranslation('group-booking');

  const classes = useAccordionStyles();
  const classesContainer = useContainerStyles();

  const { state, dispatch } = useContext(ComplexBookingContext);

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const productItemIds = query.getAll("productItemId");

  const isPackage = !!productItemIds.length;

  const [catalogService] = useState(new CatalogService(observableAxios));
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    dispatch({ type: 'SET_LOADING_PRODUCTS', data: true });
    catalogService.getCatalog(resourceUrl).subscribe((fetchedProducts) => {
      setProducts(fetchedProducts);
      fetchedProducts.forEach((p) => {
        p.items.forEach((i) => {
          if (i.selected || productItemIds.includes(i.id)) {
            dispatch({ type: "REMOVE_PRODUCT_ITEM", data: i });
            dispatch({ type: "ADD_PRODUCT_ITEM", data: i });
          }
        });
      });
      setLoading(false);
      dispatch({ type: 'SET_LOADING_PRODUCTS', data: false });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogService, dispatch, resourceUrl]);

  return loading ? (
    <Loading />
  ) : !products.length ? (
    <div>Ingen produkter tilgjengelig</div>
  ) : (
    <>
      <div>
        {t('catalog.choices')}{" "}
        {state.selectedItems.map((item, i) => (
          <span key={item.name + i}>{item.name}, </span>
        ))}
      </div>
      {products.map((product) => (
        <Accordion key={product.name} className={classes.accordion}>
          <AccordionSummary
            className={classes.accordionSummaryTransparent}
            expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
          >
            <div className={classes.container}>
              <Typography className={classes.accordionTitle}>
                {product.name}
              </Typography>
              {state.selectedItems.find(x => product.items.find(y => y.id === x.id)) ? <DarkButton
                onClick={() => {
                  product.items.filter(y => !productItemIds.includes(y.id)).forEach((item) => {
                    dispatch({ type: "REMOVE_PRODUCT_ITEM", data: item });
                  });
                }}
              >
                <CancelIcon /> {t('catalog.remove')} {product.name}
              </DarkButton> : <DarkButton>
                {t('catalog.noItemsLabel')} {product.name}
              </DarkButton>}
            </div>
          </AccordionSummary>
          <AccordionDetails
            className={`${classes.accordionDetails} ${classesContainer.wrappedContainer}`}
          >
            {!product.multiselect && (
              <>
                {product.items.map((item) => (
                  <SelectableImage
                    key={`${item.name}`}
                    label={item.name}
                    selected={
                      !!state.selectedItems.find(
                        (i) =>
                          i.id === item.id
                      )
                    }
                    imageUrl={`${FILE_API_URL}/api/fileupload/skyland-booking/${item.imageUrl}`}
                    onChange={(checked: boolean) => {
                      if(isPackage) return;
                      const productItem = product.items.find(
                        (i) => i.id === item.id
                      );
                      const currentSelected = product.items.find(
                        (i) =>
                          !!state.selectedItems.find(
                            (si) =>
                              si.id === i.id
                          )
                      );
                      const action = checked
                        ? "ADD_PRODUCT_ITEM"
                        : "REMOVE_PRODUCT_ITEM";
                      dispatch({ type: action, data: productItem });
                      currentSelected &&
                        dispatch({
                          type: "REMOVE_PRODUCT_ITEM",
                          data: currentSelected,
                        });
                    }}
                  />
                ))}
              </>
            )}
            {product.multiselect && (
              <>
                {product.items.map((item) => (
                  <SelectableImage
                    key={item.name}
                    label={item.name}
                    selected={
                      !!state.selectedItems.find(
                        (i) =>
                          i.id === item.id
                      )
                    }
                    imageUrl={`${FILE_API_URL}/api/fileupload/skyland-booking/${item.imageUrl}`}
                    onChange={(checked: boolean) => {
                      const action = checked
                        ? "ADD_PRODUCT_ITEM"
                        : "REMOVE_PRODUCT_ITEM";
                      if(checked) {
                        dispatch({ type: action, data: item });
                      } else {
                        !productItemIds.includes(item.id) && dispatch({ type: action, data: item });
                      }
                    }}
                  />
                ))}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
      {/* ALERGIES */}
      <Accordion className={classes.accordion}>
        <AccordionSummary
          className={classes.accordionSummaryTransparent}
          expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
        >
          <div className={classes.container}>
            <Typography className={classes.accordionTitle}>{t("alergies.title")}</Typography>
            {(state.alergies || !!state.selectedAlergies.length) ? <DarkButton onClick={() => {
              dispatch({ type: "SET_ALERGIES", data: "" });
              dispatch({ type: "SET_SELECTED_ALERGIES", data: [] });
            }}>
              <CancelIcon /> {t("alergies.noAlergies")}
            </DarkButton> : <DarkButton>
              {t('catalog.noItemsLabel')} {t("alergies.title")}
            </DarkButton>}
          </div>
        </AccordionSummary>
        <AccordionDetails
          className={`${classes.accordionDetails} ${classesContainer.wrappedContainer}`}
        >
          <Alergies />
        </AccordionDetails>
      </Accordion>
    </>
  );
};
