import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import BgSky from "../assets/bg_sky.png";

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    maxWidth: "40vw",
    margin: "auto",
  },
  backgroundImage: {
    backgroundImage: `url("${BgSky}")`,
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    zIndex: "-2",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  cardHeader: {
    textAlign: "center",
    color: theme.palette.primary.main
  },
})
);