import {
  CardContent,
  Card,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { FC, useContext, useState } from "react";
import { useStyles } from "../shared/styles";
import { ReservationContext } from "../shared/state";
import * as Yup from "yup";
import { BookingService } from "../services/booking.service";

import Loading from "../../shared/components/Loading";
import { observableAxios } from "../../system/rxjs-axios";
import { Reservation } from "../model/timeslot.model";
import { TextInput } from "../shared/components/TextInput";
import { ColoredText } from "../shared/components/ColoredText";
import { TextButton } from "../shared/components/TextButton";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "../shared/hooks";
import { ComplexBookingContext } from "../complex/state";
import { PolicyLink, TermsLink } from "../../shared/components/PolicyLink";

type Props = {
  bookButtonLabel: string;
};

export const ActivityConfirmBookingComponent: FC<Props> = ({
  bookButtonLabel,
}) => {
  const { t } = useTranslation('activity-booking');

  const navigate = useNavigate();

  const [bookingService] = useState(new BookingService(observableAxios));
  const classes = useStyles();

  const { state } = useContext(ReservationContext);
  const { state: complexState, dispatch: dispatchComplex } = useContext(
    ComplexBookingContext
  );
  const [runningCodeCheck, setRunningCodeCheck] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [mobile, setMobile] = useState("");
  const mobileValidation = Yup.string()
    .required("Must be filled in")
    .matches(/^[0-9+]{8,13}$/, { message: "Must be a valid mobile number" });
  
  const makeReservation = (retryCount:number=0) => {
    setRunningCodeCheck(true);
    state.reservations.forEach(r=>{
      r.mobile = mobile;
      let price = complexState.activityPrices[r.referenceId];
      if(price){
        r.unitPrice = price;
      }
    });
    var reservations = state.reservations;

    bookingService.makeReservations(reservations).subscribe(
      (result: Array<Reservation>) => {
        
        var activityReservation = result.find(
          (q) => q.referenceNumber
        );
        if (activityReservation) {
          navigate(`/ref-number-vipps/${activityReservation.referenceNumber}?mobile=${encodeURIComponent(mobile)}`);
        }
        if (state.priceInfo?.campaign) {
          document.location.href =
            "https://kampanje.skyland.no/booket?fbclid=IwAR2GZitEkyymefOezazeWC_Shv9pndft8nNKgOjzTJPcPCtcqPZ6TpixDwE";
        }
        setErrorMessage(null);
        setRunningCodeCheck(false);
      },
      (err) => {
        if(!err.response){
          if(retryCount > 3){
            setErrorMessage(t('errors.server')); // Possible server restart Retry?
          }
          else{
            setTimeout(()=>{
              makeReservation(retryCount+1);
            },retryCount*1000);
            return;
          }
        } else if (err.response.status === 400 && err.error && err.error.full) {
          setErrorMessage(t('errors.full'));
        } else if (err.response.status && err.response.status === 400) {
          setErrorMessage(t('errors.mobile'));
        } else if (err.response.status === 424) {
          setErrorMessage(t('errors.machine'));
        } else if (err.response.status >= 500) {
          setErrorMessage(t('errors.server'));
        } else {
          setErrorMessage(t('errors.something'));
        }
        setRunningCodeCheck(false);
      }
    );
  };

  return (
    <Card className={classes.bookingCard}>
        <CardContent>
          <span>{t('confirmation.subtitle')}</span>
          {runningCodeCheck && (
            <div>
              <Loading />
            </div>
          )}
          <div>
            <TextInput
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              disabled={!state.isValid}
              label={t("confirmation.phoneNumberInputLabel")}
            />
          </div>
          <div style={{ marginTop: '1em' }}>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label={
                <Trans i18nKey="consent" ns="activity-booking">
                  <span></span>
                  <TermsLink>
                    <ColoredText></ColoredText>
                  </TermsLink>
                  <span></span>
                  <PolicyLink >
                    <ColoredText></ColoredText>
                  </PolicyLink>
                  <span></span>
                </Trans>
              }
            />
          </div>
          <div className={classes.buttonsContainer}>
            <TextButton
              variant="contained"
              onClick={()=>makeReservation()}
              disabled={!state.isValid || !mobileValidation.isValidSync(mobile) || runningCodeCheck}
            >
              {runningCodeCheck ? <Loading size={30} sx={{ color: '#fff' }} /> : bookButtonLabel}
            </TextButton>
          </div>
        </CardContent>
      {errorMessage !== null && (
        <CardContent>
          <div className="matcard-margin">
            <div className="textbox-top-margin msgerror">
              <span>{t('errors.errorOccured')}</span>
            </div>
            <div>
              <span className="msgerror">{errorMessage}</span>
            </div>
          </div>
        </CardContent>
      )}
    </Card>
  );
};
