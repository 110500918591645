import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { StatePreview as StatePreviewComplex } from "./StatePreview";
import { useStatePreviewStyles } from "./styles";

type Props = {
  dialogOpen: boolean;
  setDialogOpen: (v: boolean) => any;
  StatePreview?: FC<any>;
  dialogOnly?: boolean;
}

export const ResponsiveStatePreview: FC<Props> = ({ dialogOpen, setDialogOpen, StatePreview = StatePreviewComplex,dialogOnly }) => {
  const classes = useStatePreviewStyles();
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        PaperProps={{
          className: classes.bookingCard,
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.9)'
          }
        }}
        scroll="body"
        fullScreen={isSM}
      >
        <StatePreview onClose={() => setDialogOpen(false)} />
      </Dialog>
      {!dialogOnly && <div className={classes.bigScreenVisible}>
        <StatePreview />
      </div>}
    </>
  )
}