interface EnvironmentConfig {
  ENTITY_API_URL: string;
  FILE_API_URL: string;
  TENANT_ID: string;
  WAIVER_API_URL: string;
  SIGNATURE_LOG_API_URL: string;
  WAIVER_RULES_API_URL: string;
  CATALOG_API_URL: string;
  SCHEDULING_API_URL: string;
}

const w: { _env_: EnvironmentConfig; } = window as any;

export const ENTITY_API_URL = w._env_?.ENTITY_API_URL ?? "";
export const FILE_API_URL = w._env_?.FILE_API_URL ?? "";
export const TENANT_ID = w._env_?.TENANT_ID ?? "";

export const WAIVER_API_URL = w._env_?.WAIVER_API_URL ?? "";
export const SIGNATURE_LOG_API_URL = w._env_?.SIGNATURE_LOG_API_URL ?? "";
export const WAIVER_RULES_API_URL = w._env_?.WAIVER_RULES_API_URL ?? "";
export const CATALOG_API_URL = w._env_?.CATALOG_API_URL ?? "";
export const SCHEDULING_API_URL = w._env_?.SCHEDULING_API_URL ?? "";

export const IMAGES_API_URL = `${FILE_API_URL}/api/fileupload/skyland-booking`;