import axios, { AxiosRequestConfig } from "axios";
import { Observable } from "rxjs";

const observableHandler = {
  get: function (target: any, prop: any, receiver: any) {
    if (typeof target[prop] === "function") {
      return function (...args: any[]) {
        return new Observable((observer) => {
          target[prop](...args)
            .then((response: { data: any }) => {
              observer.next(response.data);
              observer.complete();
            })
            .catch((error: Error) => {
              console.error(args, error)
              observer.error(error);
            });
        });
      };
    }
    return target[prop];
  },
};

export interface ObservableAxios {
  get(url: string, config?: AxiosRequestConfig<any>): Observable<any>;
  put(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<any>
  ): Observable<any>;
  post(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<any>
  ): Observable<any>;
  delete(
    url: string,
    config?: AxiosRequestConfig<any>
  ): Observable<any>;
}

export const observableAxios: ObservableAxios = new Proxy(
  axios,
  observableHandler
);
