import { Route } from "react-router-dom";
import { SignConfirmation } from "./SignConfirmation";
import { useDetailsReducer, DetailsContext } from "../details/state";
import { WaiverDetails } from "./WaiverDetails";

export const WaiverRoutes = () => {
  const [state, dispatch] = useDetailsReducer();

  return (
    <>
      <DetailsContext.Provider value={{state, dispatch }}>
        <Route exact path="/:lang/egenerklering"><WaiverDetails /></Route>
        <Route exact path="/:lang/egenerklering/:refId"><WaiverDetails /></Route>
        <Route exact path="/:lang/egenerklering/:refId/confirmed"><SignConfirmation /></Route>
      </DetailsContext.Provider>
    </>
  );
}