import { FC } from "react";
import { useBadgeStyles } from "../../complex/styles";

type Props = {
  grayedOut?: boolean;
}

export const Badge: FC<Props> = ({ children, grayedOut = false }) => {
  const classes = useBadgeStyles({ grayedOut });

  return (
    <span className={classes.badge}>
      {children}
    </span>
  )
}