import moment from "moment";
import { Observable, map } from "rxjs";
import { SCHEDULING_API_URL } from "../../environment";
import { ReceiptDataVipps } from "../../payment/model/receipt-data-vipps";
import { ObservableAxios } from "../../system/rxjs-axios";
import { DayScheduleTypes } from "../model/day-schedule.model";

export class PaymentService {
  private readonly base_schedule_url = SCHEDULING_API_URL;
  private readonly payments_url = this.base_schedule_url + "/api/payments";
  private readonly search_reservation_url =
    this.base_schedule_url + "/api/payments/searchreservation";
  private readonly receipt_data_vipps_url =
    this.base_schedule_url + "/api/vipps/receiptdata";
  private readonly finalize_vipps_url =
    this.base_schedule_url + "/api/vipps/finalize";

  constructor(private http: ObservableAxios) {}

  public searchReservationToPay(
    referenceNumber: string,
    mobile: string
  ): Observable<SearchPaymentResult> {
    return this.http
      .get(`${this.search_reservation_url}/${referenceNumber}/${mobile}`)
      .pipe(
        map((obj: any) => {
          return SearchPaymentResult.deSerialize(obj);
        })
      );
  }

  public startVippsPayment(searchResult: SearchPaymentResult, setMessage: (v: string) => any) {
    setMessage("");

    if (!searchResult) {
      setMessage("Det må søkes opp en reservasjon for betaling.");
      return;
    }

    var apiUrl = SCHEDULING_API_URL;
    var url = `${apiUrl}/api/vipps/initiate/${searchResult.reservationId}`;

    this.http.post(url, null).subscribe((res: any) => {
      var url = res.redirectUrl;

      if (!url) {
        console.error("Redirect url missing!");
        setMessage("En feil oppstod ved kontakt mot betalingstjenesten. Vennligst last inn siden på nytt og prøv igjen.");
        return;
      }
      window.location.href = url;
    });
  }

  public getReceiptDataVipps(orderId: string): Observable<ReceiptDataVipps> {
    return this.http.get(`${this.receipt_data_vipps_url}/${orderId}`).pipe(
      map((obj: any) => {
        return ReceiptDataVipps.deSerialize(obj);
      })
    );
  }

  public finalize(orderId: string): Observable<FinalizeVippsResult|null> {
    return this.http.post(`${this.finalize_vipps_url}/${orderId}`, null).pipe(
      map((obj: any) => {
        return FinalizeVippsResult.deSerialize(obj);
      })
    );
  }
}

export class SearchPaymentResult {
  constructor(
    public reservationId: string,
    public startTime: moment.Moment,
    public count: number,
    public type: DayScheduleTypes,
    public isPaid: boolean
  ) {}

  public static deSerialize(obj: any): SearchPaymentResult {
    return new SearchPaymentResult(
      obj.reservationId,
      moment(obj.startTime),
      obj.count,
      obj.type,
      obj.isPaid
    );
  }
}

export class FinalizeVippsResult {
  constructor(
    public statuc: FinalizeStatus,
    public receiptData: ReceiptDataVipps
  ) {}

  public static deSerialize(json: any): FinalizeVippsResult|null {
    if (!json) {
      return null;
    }

    const receiptData = ReceiptDataVipps.deSerialize(json.receiptData);

    return new FinalizeVippsResult(json.status, receiptData);
  }
}

export enum FinalizeStatus {
  NotCompleted = -1,
  PreviouslyCompleted = 0,
  Completed = 1,
}
