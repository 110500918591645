import { Button, Tooltip, Typography } from "@mui/material";
import { FC, useState } from "react";
import { BookingService } from "../booking/services/booking.service";
import { TextButton } from "../booking/shared/components/TextButton";
import { observableAxios } from "../system/rxjs-axios";

import VippsImg from "../assets/vipps_logo.png";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

type Props = {
  reservationId: string;
};

export const Vipps: FC<Props> = ({ reservationId }) => {
  const { t } = useTranslation("ref-number");

  const classes = useStyles();

  const [bookingService] = useState(new BookingService(observableAxios));
  const [vippsErrorMessage, setVippsErrorMessage] = useState<string>("");

  return (
    <>
      <Button
        className={classes.vippsBasicButton}
        variant="contained"
        onClick={() => bookingService.startVippsPayment(reservationId, setVippsErrorMessage)}
        >
        {t("ok.payWithVipps")}
      </Button>
      <TextButton
        className={classes.vippsButton}
        onClick={() =>
          bookingService.startVippsPayment(reservationId, setVippsErrorMessage)
        }
      >
        <Tooltip title={<>{t("ok.payWithVipps")}</>}>
          <img src={VippsImg} className={classes.vippsImage} alt="Vipps" />
        </Tooltip>
      </TextButton>
      {vippsErrorMessage && (
        <Typography color="red">{vippsErrorMessage}</Typography>
      )}
    </>
  );
};
