/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useDetailsStyles } from "../../details/styles";
import { observableAxios } from "../../system/rxjs-axios";
import { BookingService } from "../services/booking.service";
import { ColoredText } from "../shared/components/ColoredText";
import { ReservationContext } from "../shared/state";
import * as Yup from "yup";
import { Reservation } from "../model/timeslot.model";
import Loading from "../../shared/components/Loading";
import { SignatureLogService } from "../../signature-log/signature-log.service";
import { Guid } from "../../system/guid";
import { DetailsContext } from "../../details/state";
import moment from "moment";
import { GroupReservation } from "../model/GroupReservation";
import { ComplexBookingContext, getNeedsLongerDuration,getSelectedDuration} from "./state";
import { TimeScheduleService } from "../services/time-schedule.service";
import { WaverFromService } from "../../waiver/services/waver-from.service";
import { TextInput } from "../shared/components/TextInput";
import { TextButton } from "../shared/components/TextButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "../shared/hooks";
import { useParams } from "react-router-dom";
import { useWaiverRules } from "../../waiver/services/useWaiverRules";

type Props = {};

export const Confirmation: FC<Props> = () => {
  const { t } = useTranslation('group-booking');

  const navigate = useNavigate();

  const classesDetails = useDetailsStyles();

  const [bookingService] = useState(new BookingService(observableAxios));
  const [signatureService] = useState(new SignatureLogService(observableAxios));
  const [timeScheduleService] = useState(
    new TimeScheduleService(observableAxios)
  );
  const { state } = useContext(ReservationContext);
  const { state: detailsState } = useContext(DetailsContext);
  const { state: complexState, dispatch: dispatchComplex } = useContext(
    ComplexBookingContext
  );
  
  const [bookedOk, setBookedOk] = useState(false);
  const [runningCodeCheck, setRunningCodeCheck] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isConfirmationOk, setIsConfirmationOk] = useState<boolean>(false);
  const [confirmLegal, setConfirmLegal] = useState<boolean>(false);

  const [service] = useState(
    new WaverFromService(
      observableAxios,
      new SignatureLogService(observableAxios)
    )
  );

  const { lang } = useParams<{ lang: string }>();
  const { rules } = useWaiverRules(lang);

  const mobileValidation = Yup.string()
    .required("Must be filled in")
    .matches(/^[0-9]{8}$/, { message: "Must be a valid mobile number" });
  const details = detailsState;
  const tenantId = state?.activity?.tenantId;
  const mobile = details?.mobile;

  // TODO: Should this be used?
  const [confirmationLog, setConfirmationLog] = useState({
    id: Guid.empty().toString(),
    documentId: details.id,
    logEntry: { mobile: mobile, code: "" },
    occuredTime: moment().utc(),
    tenantId: tenantId,
  });

  const [smsCodeRequested,setSmsCodeRequested] = useState<boolean>(false);
  const requestConfirmation = () => {
    setRunningCodeCheck(true);

    setErrorMessage(null);
    setIsConfirmationOk(false);

    signatureService
      .requestConfirmation({
        id: Guid.empty().toString(),
        documentId: details.id,
        logEntry: { mobile: mobile },
        occuredTime: moment().utc(),
        tenantId: tenantId,
      })
      .subscribe({
        next: (result: any) => {
          setErrorMessage(null);
          setRunningCodeCheck(false);
          setSmsCodeRequested(true);
        },
        error: (err: any) => {
          if(!err.response){
            setErrorMessage(t('errors.server'));
          } else if(!err.response){
            setErrorMessage(t('errors.something'));
          } else if (err.response.status === 400 && err.error && err.error.full) {
            setErrorMessage(t('errors.full'));
          } else if (err.response.status && err.response.status === 400) {
            setErrorMessage(t('errors.mobile'));
          } else if (err.response.status === 424) {
            setErrorMessage(t('errors.machine'));
          } else if (err.response.status >= 500) {
            setErrorMessage(t('errors.server'));
          } else {
            setErrorMessage(t('errors.something'));
          }
          setRunningCodeCheck(false);
          setIsConfirmationOk(false);
        },
      });
  };

  const prepareGroupReservation = (): any => {
    if(!state.reservations) throw "No reservations to book";
    const emptyGuid = Guid.empty().toString();
    detailsState.tenantId = tenantId;
    detailsState.documentId = emptyGuid;
    var details = detailsState;
    state.reservations.forEach(r=>{
      r.mobile = details.mobile;
    });
    
    const activityReservation =  state.reservations.find(
      (x) => x.type === "ActivityReserved" && x.referenceId === state.activity.id
    );
    var subActivityReservations = activityReservation? state.reservations.filter(x=>x.id != activityReservation.id):[];
    if (!activityReservation) return;
    var groupReservation: GroupReservation = {
      id: details.id,
      details: details,
      extras: complexState.selectedItems.map((x)=>({...x,description:x.name})),
      message:
        complexState.alergies +
        (complexState.selectedAlergies.length
          ? `\nAlergies: ${complexState.selectedAlergies.join(", ")}`
          : ""),
      reservationId: activityReservation.reservationId,
      reservation: activityReservation,
      reservationDate: activityReservation.startTime,
      reservations: subActivityReservations,
      reservationIds: state.reservations ? state.reservations.map((x) => x.reservationId) : [],
    };

    let confirmationLog = {
      id: emptyGuid,
      documentId: details.id,
      logEntry: { mobile: mobile, code: complexState.code },
      occuredTime: moment().utc(),
      tenantId: tenantId,
    };
    return {
      id: emptyGuid,
      waiverRules: rules,
      reservation: activityReservation,
      groupReservation: groupReservation,
      signature: confirmationLog,
    };
  };

  const submitCode = (retryCount:number = 0) => {
    var groupReservationRequest = prepareGroupReservation();
    setErrorMessage(null);
    setBookedOk(true);
    setRunningCodeCheck(true);

    timeScheduleService
      .makeGroupeReservation2(groupReservationRequest)
      .subscribe({
        next: (result: Reservation) => {
          setIsConfirmationOk(true);
          setErrorMessage("");
          setRunningCodeCheck(false);
          dispatchComplex({ type: 'RESET_MAKE_RESERVATION', data: {} });
          navigate(`/ref-number/${result.referenceNumber}`);
        },
        error: (err: any) => {
          if(!err.response){
            if(retryCount > 3){
              setErrorMessage(t('errors.server')); // Possible server restart Retry?
            }
            else{
              setTimeout(()=>{
                submitCode(retryCount+1);
              },retryCount*1000);
              return;
            }
          }
          if(!err.response){
            setErrorMessage(t('errors.something'));
          } else if (err.response?.status === 400 && err.error && err.error.full) {
            setErrorMessage(t('errors.full'));
          } else if (err.response.status && err.response.status === 400) {
            setErrorMessage(t('errors.mobile'));
          } else if (err.response.status === 424) {
            setErrorMessage(t('errors.machine'));
          } else if (err.response.status >= 500) {
            setErrorMessage(t('errors.server'));
          } else {
            setErrorMessage(t('errors.something'));
          }
          setBookedOk(false);
          setRunningCodeCheck(false);
          setIsConfirmationOk(false);
          dispatchComplex({ type: 'RESET_MAKE_RESERVATION', data: {} });
        },
      });
  };

  const checkCode = () => {
    submitCode();
  };
  const makeReservation = () => {
    checkCode();
  };

  useEffect(() => {
    if(complexState.makeReservation) {
      makeReservation();
    }
  }, [complexState.makeReservation]);
  
  const needsLongerDuration = getNeedsLongerDuration(state,complexState);
  const hasDefaultSubActivity = state.hasDefaultSubActivity;
  const selectedDuration = getSelectedDuration(state,complexState);
  const needsMoreActiveties = !hasDefaultSubActivity && (state.priceInfo?.duration??0) > selectedDuration;

  const isValid = (state.isValid && detailsState.isValid &&
    !complexState.makeReservation &&
    !needsLongerDuration &&
    !needsMoreActiveties);
  return (
    <div>
      <div className={classesDetails.paddingContainer}>
        <ColoredText>{t("confirmation.label")}</ColoredText>
      </div>
      <div
        className={`${classesDetails.paddingContainer} ${classesDetails.smallerText}`}
      >
        {t("confirmation.note")}
      </div>
      <div
        className={`${classesDetails.paddingContainer} ${classesDetails.smallerText}`}
      >
        {rules.map((rule, i) => (
          <li key={`rule-${i}`}>{rule}</li>
        ))}
      </div>
      <div
        className={`${classesDetails.paddingContainer} ${classesDetails.smallerText}`}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={confirmLegal}
              onChange={() => setConfirmLegal(!confirmLegal)}
            />
          }
          label={t("confirmation.consent")}
        />
      </div>
      {!bookedOk && (
        <CardContent>
          <div style={{marginBottom: '1em'}}>
            <TextButton
              variant="contained"
              onClick={requestConfirmation}
              disabled={!confirmLegal || runningCodeCheck || !isValid}
            >
              {t("confirmation.confirmButton")}
            </TextButton>
          </div>
          {smsCodeRequested && <span>{t("confirmation.subtitle")}</span>}
          {runningCodeCheck && (
            <div>
              <Loading />
            </div>
          )}
          <div style={{marginBottom: '1em'}}>
            {smsCodeRequested && <TextInput autoComplete="off"
              label={'*' + t("confirmation.smsCodeInputLabel")}
              variant="standard"
              value={complexState.code}
              onChange={(e) => dispatchComplex({ type: 'SET_CODE', data: e.target.value })}
            />}
          </div>
        </CardContent>
      )}
      {!!errorMessage && (
        <CardContent>
          <div className="matcard-margin">
            <div className="textbox-top-margin msgerror">
            <span>{t('errors.errorOccured')}</span>
            </div>
            <div>
              <span className="msgerror">{errorMessage}</span>
            </div>
          </div>
        </CardContent>
      )}
    </div>
  );
};
