import { Observable } from 'rxjs';
import { SIGNATURE_LOG_API_URL } from '../environment';
import { SignatureLog } from './signature-log';

export class SignatureLogService {
  // TODO Add separet config for LOG service
  private readonly apiUrl = SIGNATURE_LOG_API_URL + "/api/signaturelog";
  
  constructor(private http: any) { }

  public requestConfirmation(entry: SignatureLog): Observable<any> {
    return this.http.post(`${this.apiUrl}/confirmation_request`, entry);
  }

  get(): any {
    return this.http.get(this.apiUrl);
  }

  getSignatureLog(documentId:string): any {
    return this.http.get(`${this.apiUrl}/${documentId}`);
  }
}
