import { createContext, Dispatch, useReducer } from "react";
import { createChainOfHandlersFor } from "../../shared/utils/chain";
import { PriceInfo } from "../../shared/model/day-schedule.model";
import { Schedule } from "../model/schedule.model";
import { Reservation, TimeSlot } from "../model/timeslot.model";
import { Activity, ActivityType } from "../model/activity.model";
import { Guid } from "../../system/guid";

export type ReservationState = {
  date: Date;
  count: number;
  bookedCount: number;
  priceInfo: PriceInfo;
  timeSlot: TimeSlot;
  isValid: boolean;
  schedule: Schedule;
  totalPrice: number;
  activity: Activity;
  reservations: Reservation[],
  hasDefaultSubActivity:boolean,
}

const initialState: ReservationState = {

} as any;

type Action = {
  type: string;
  data: any;
}

const validityHandler = (state: ReservationState): ReservationState => {
  const { date, count, priceInfo, timeSlot } = state;
  return {
    ...state,
    isValid: date != null && count != null && priceInfo != null && timeSlot != null
  }
}

const priceHandler = (state: ReservationState): ReservationState => {
  const { count, priceInfo } = state;
  let minGroupSize = state?.activity?.minGroupSize? state?.activity?.minGroupSize : 1;
  let isPayPrResource = state?.activity?.activityType === ActivityType.PayPrResource;
  const hasMinGroupSize = minGroupSize && minGroupSize > 0;
  let bookedCount = isPayPrResource? 1 : hasMinGroupSize 
    && (!count || count < minGroupSize)? minGroupSize: count;
  return {
    ...state,
    totalPrice: (bookedCount) * (priceInfo?.price ?? 0),
    bookedCount,
  }
}


const chain = createChainOfHandlersFor<ReservationState>([validityHandler, priceHandler]);

const reducer = (state: any, action: Action): ReservationState => {
  switch (action.type) {
    case "SET_DATE":
      return chain({
        ...state,
        date: action.data,
      });
    case "SET_COUNT":
      return chain({
        ...state,
        count: action.data,
      });
    case "SET_PRICE_INFO":
      return chain({
        ...state,
        priceInfo: action.data,
      });
    case "SET_TIME_SLOT":
      return chain({
        ...state,
        timeSlot: action.data,
      });
    case "SET_SCHEDULE":
      return chain({
        ...state,
        schedule: action.data,
      });
    case "SET_ACTIVITY":
      const hasDefaultSubActivity = action.data?.defaultSubActivityId !== Guid.empty().toString();
      return chain({
        ...state,
        activity: action.data,
        hasDefaultSubActivity,
      });
    case "SET_RESERVATIONS":
      return chain({
        ...state,
        reservations: action.data,
      });
    default:
      return state;
  }
}

export const useReservationReducer = () => useReducer(reducer, initialState);

type ReservationContextType = {
  state: ReservationState;
  dispatch: Dispatch<Action>;
};

export const ReservationContext = createContext<ReservationContextType>({ state: initialState, dispatch: () => {} });