import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import { useStyles } from "./styles";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikField from "../shared/components/FormikField";
import { useState } from "react";
import Loading from "../shared/components/Loading";
import { PaymentService, SearchPaymentResult } from "../shared/services/payment.service";
import { observableAxios } from "../system/rxjs-axios";

export const Payment = () => {
  const classes = useStyles();

  const [paymentService] = useState(new PaymentService(observableAxios));

  const [isSearchingReservation, setSearchingReservation] = useState(false);

  const [message, setMessage] = useState("");

  const [searchResult, setSearchResult] = useState<SearchPaymentResult>();

  const validation = Yup.object().shape({
    mobile: Yup.string()
      .required("Must be filled in")
      .matches(/^[0-9]{8}$/, { message: "Must be a valid mobile number" }),
    referenceNumber: Yup.string().required("Must be filled in"),
  });

  const searchReservation = (values: { mobile: string, referenceNumber: string }, isValid: boolean) => {
    setMessage("");
    setSearchingReservation(true);

    const mobile = values.mobile;
    const referenceNumber = values.referenceNumber;

    if (!mobile || mobile === "" || !referenceNumber || referenceNumber === "") {
      setMessage("Fyll inn mobil og referansenummer");
      return;
    }

    paymentService
      .searchReservationToPay(referenceNumber, mobile)
      .subscribe(
        (result: SearchPaymentResult) => {
          setSearchResult(result);
          setSearchingReservation(false);
        },
        (error) => {
          if (error.response.status === 404) {
            setMessage("Fant ikke reservasjon. Kontroller mobil/referansenummer og prøv igjen.");
          } else {
            console.error("Error:", error);
            setMessage("En feil oppstod, vennligst prøv igjen.");
          }
          setSearchingReservation(false);
        }
      );
  }

  return (
    <Card className={classes.root}>
      <CardHeader title="Betaling" subheader="Søk opp reservasjonen som skal betales" className={classes.cardHeader}/>
      <CardContent>
        {isSearchingReservation && <div>
          <Loading />
        </div>}
        {!isSearchingReservation && <Formik
          initialValues={{
            mobile: "",
            referenceNumber: ""
          }}
          validationSchema={validation}
          onSubmit={(values, err) => {
            searchReservation(values, validation.isValidSync(values));
          }}
        >
          {() => (
            <Form>
              <FormikField
                variant="standard"
                label="*Mobil"
                name="mobile"
              />
              <FormikField
                variant="standard"
                label="*Referansenummer"
                name="referenceNumber"
              />
              <Button
                type="submit"
                variant="contained"
              >
                Søk etter reservasjon 
              </Button>
            </Form>
          )}
        </Formik>}
      </CardContent>
      <CardActions>
        {!!message && <span>{message}</span>}
        {searchResult && !searchResult.isPaid && <>
          <div>
            Fant reservasjon {searchResult.startTime.format("DD.MM.YYYY")} kl. {searchResult.startTime.format("HH.mm")} for
            {searchResult.count} {searchResult.count > 1 ? "personer" : "person"}.
          </div>
          <div>
            <Button
              variant="contained"
              onClick={() => paymentService.startVippsPayment(searchResult, setMessage)}
            >
              Betal med Vipps
            </Button>
          </div>
        </>}
        {searchResult?.isPaid && <span>Reservasjonen er allerede betalt.</span>}
      </CardActions>
    </Card>
  );
};
