import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useComplexStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("lg")]: {
        display: "flex",
        justifyContent: "space-evenly",
      },
    },
    confirmStepContainer: {
      maxWidth: "80vw",
      margin: "auto",
    },
    totalPriceContainer: {
      display: "flex",
      justifyContent: "space-between",
      fontWeight: 550,
    },
  })
);

export const useContainerStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrappedContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
      gap: '3.7%',
      flexWrap: 'wrap'
    },
    verticalContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      '& > *': {
        width: '100%'
      }
    }
  })
);

export const useAccordionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        margin: '0 2em 0 0'
      },
      maxHeight: '75vh',
      overflowY: 'scroll',
      [theme.breakpoints.down('md')]: {
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '& > button': {
          position: 'fixed',
          right: '20px',
          bottom: '20px',
        },
      },
      [theme.breakpoints.up('md')]: {
        paddingRight: '12px',
        '&::-webkit-scrollbar': {
          width: '12px',
          backgroundColor: 'black',
        },
        '&::-webkit-scrollbar-track': {
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
          borderRadius: '10px',
          backgroundColor: '#0a0909',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
          backgroundColor: '#241926',
        }
      },
    },
    accordion: {
      borderRadius: `${theme.border?.radius} !important`,
      marginBottom: "1em",
      width: '100%'
    },
    accordionDisabled: {
      opacity: 0.5,
      '& button': {
        opacity: 1
      }
    },
    accordionSummary: {
      height: "60px",
      borderRadius: `${theme.border?.radius} ${theme.border?.radius} 0 0`,
      padding: "1em",
      display: 'flex',
      alignItems: 'center',
    },
    lockIcon: {
      marginLeft: 'auto',
      marginTop: '3px',
      marginRight: '10px'
    },
    accordionDetails: {
      backgroundColor: theme.palette.background.default,
      borderRadius: `0 0 ${theme.border?.radius} ${theme.border?.radius}`,
    },
    accordionTitle: {
      fontSize: "20px",
      [theme.breakpoints.down('md')]: {
        fontSize: "15px",
      },
      fontWeight: "normal",
      marginLeft: "0.5em",
    },
    accordionDetailsSummary: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      opacity: '1 !important'
    },
    accordionSummaryTransparent: {
      height: "30px",
      padding: '0',
      borderRadius: `${theme.border?.radius} ${theme.border?.radius} 0 0`,
      backgroundColor: theme.palette.background.default,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }
  })
);

export const usePageTitleStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '2em',
      width: '100%',
      '& p': {
        marginLeft: '1em',
        fontSize: '28px',
      },
      '& span svg': {
        fontSize: '31px',
      },
      [theme.breakpoints.down('lg')]: {
        marginBottom: '0.7em',
        '& span svg': {
          fontSize: '20px',
        },
        '& p': {
          fontSize: '20px',
        },
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: '0.5em',
        '& span svg': {
          fontSize: '18px',
        },
        '& p': {
          fontSize: '14px',
        },
      }
    },
    previewButton: {
      marginLeft: 'auto',
      '& span': {
        padding: '0.25em',
        fontWeight: 'normal !important',
        fontSize: '18px',
      },
      '& span svg': {
        margin: '0.2em',
        fontSize: '1.5em',
      },
      [theme.breakpoints.down('md')]: {
        '& span': {
          padding: '0.2em',
          fontSize: '17px',
        },
        '& span svg': {
          fontSize: '1em',
          margin: '0.2em',
        },
      }
    },
    bigScreenHidden: {
      [theme.breakpoints.up('lg')]: {
        display: 'none'
      }
    },
  })
);

export const useBadgeStyles = makeStyles((theme: Theme) =>
  createStyles({
    badge: {
      borderRadius: '5px',
      backgroundColor: (props: any) => props.grayedOut ? 'gray' : theme.palette.primary.main,
      padding: '0.5em',
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#fff',
    },
    square: {
      display: 'flex',
      fontSize: '15px',
      width: '15px',
      height: '15px',
      justifyContent: 'center',
      alignItems: 'center'
    },
  })
);

export const useLineStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      height: '1px',
      border: '0',
      borderTop: `2px solid ${theme.palette.primary.main}`,
      margin: '1em 0',
      padding: '0',
    },
  })
);

export const useStatePreviewStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigScreenVisible: {
      [theme.breakpoints.down('lg')]: {
        display: 'none'
      }
    },
    cardHeader: {
      backgroundColor: theme.palette.background.paper,
      '& span': {
        padding: '0.2em',
        marginRight: '0.5em',
        fontSize: '20px'
      }
    },
    cardHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    summaryGridContainer: {
      padding: '0.5em',
      '& > div': {
        marginBottom: '0.8em',
      },
      minWidth: '600px',
      [theme.breakpoints.down('md')]: {
        minWidth: '100%',
      }
    },
    numbersGridContainer: {
      padding: "0.5em 0 0 0",
    },
    namesGridContainer: {
      padding: "0 0 0 0.5em",
      fontSize: '16px',
    },
    contentGridContainer: {
      fontSize: '14px',
      padding: "0 0.5em 0 1em",
      maxWidth: '240px'
    },
    activityBookingCartGrid: {
      fontSize: '14px',
      padding: "0 0.5em 0 1em",
      // maxWidth: '240px'
    },
    bookingCard: {
      borderRadius: theme.border?.radius,
      background: theme.palette.background.default,
      [theme.breakpoints.up('lg')]: {
        width: '544px'
      }
    },
    totalPrice: {
      paddingLeft: '2em'
    },
  })
);

export const useButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    textButton: {
      textTransform: 'none',
      borderRadius: theme.border?.radius,
      '&:disabled, &[disabled]': {
        backgroundColor: theme.palette.primary.dark,
        color: 'gray'
      }
    },
    darkButton: {
      textTransform: 'none',
      borderRadius: theme.border?.radius,
      backgroundColor: '#000',
      color: theme.palette.primary.main,
      padding: '0.2em 0.7em',
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        color: theme.palette.primary.main,
        marginRight: '10px'
      }
    }
  })
);

export const useTextStyles = makeStyles((theme: Theme) =>
  createStyles({
    coloredText: {
      color: theme.palette.primary.main
    },
  })
);

type ImageStyleProps = { imageUrl?: string, selected?: boolean };

export const useImageStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectableImage: {
      cursor: 'pointer',
      width: '150px',
      height: '150px',
      outline: ({ selected }: ImageStyleProps) => selected ? `3px solid ${theme.palette.primary.main}` : '',
      borderRadius: theme.border?.radius,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '150px 150px',
      backgroundImage: ({ imageUrl }: ImageStyleProps) => `url('${imageUrl}')`,
      marginTop: '40px',
      position: 'relative',
      '& label': {
        position: 'absolute',
        top: '-30px',
        backgroundColor: "rgba(19, 13, 20, 0.7)",
      },
      '& span': {
        position: 'absolute',
        right: '5px',
        top: '5px',
        display: 'flex',
        padding: '4px',
        fontSize: '11px',
        fontWeight: 'normal',
      },
      '& span svg': {
        fontSize: '16px',
        marginLeft: '5px'
      },
      '& span div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

      },
      webkitTouchCallout: 'none',
      webkitUserSelect: 'none',
      khtmlUserSelect: 'none',
      mozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
    },
    selectableImageGroup: {
      fontSize: '14px !important'
    }
  })
);

export const useInputStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputContainer: {
      position: 'relative',
      marginTop: '35px',
      display: 'inline-flex',
      '& label': {
        fontSize: '14px',
        position: 'absolute',
        top: '-30px',
        left: '10px',
        '& svg': {
          color: theme.palette.primary.main,
          marginLeft: '15px'
        }
      }
    },
    field: {
      padding: '0.2em 0.5em 0.2em 0.5em',
      margin: '0.2em 0.5em 0.2em 0.5em',
      border: '1px solid #fff',
      borderRadius: theme.border?.radius,
      fontSize: '14px',
      maxWidth: '150px',
    },
    selectField: {
      minWidth: '150px',
      '& .MuiSelect-icon': {
        color: '#fff'
      },
      "& .Mui-disabled": {
        color: "#9c9a9a !important",
        '-webkit-text-fill-color': '#9c9a9a'
      },
      "& div .Mui-disabled": {
        color: "#9c9a9a !important",
        '-webkit-text-fill-color': '#9c9a9a'
      },
    },
    white: {
      color: '#fff'
    },
    fontSize: {
      fontSize: '14px'
    },
    unsetMaxWidth: {
      maxWidth: 'unset !important'
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    errorMessage: {
      marginLeft: '1em',
      fontStyle: 'italic',
      fontSize: '12px',
      color: 'red'
    }
  })
);