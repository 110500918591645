import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      zIndex: "-2",
      backgroundColor: 'black'
    },
    bookingCard: {
      borderRadius: '10px',
      background: theme.palette.background.default,
      [theme.breakpoints.up('md')]: {
        maxWidth: '512px'
      }
    },
    dropInBookingCard: {
      borderRadius: '10px',
      background: theme.palette.background.default,
      [theme.breakpoints.up('md')]: {
        maxWidth: '700px'
      }
    },
    descriptionContainer: {
      margin: "1em 1em 2em 1em",
      fontSize: "14px",
    },
    // old down
    cardBoxstyle: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '100%',
      boxSizing: 'border-box',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    cardBoxstyleStart: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '100%',
      boxSizing: 'border-box',
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      alignItems: 'flex-start',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '1em'
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: "1em",
      padding: '0.2em'
    },
    salesTermsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1vw'
    },
    salesTerms: {
      '& a': {
        color: 'rgba(0,0,0,.54)',
        textDecoration: "none"
      }
    },
    vippsImage: {
      maxWidth: '64px'
    },
    smallText: {
      fontSize: '14px'
    },
    clickable: {
      cursor: 'pointer'
    }
  })
);

export const useOrderPreviewStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      padding: '0.6em 2em 0.6em 2em'
    },
    child: {
      flex: '50%',
      flexBasis: '50%',
    },
    childInner: {
      margin: '0.6em',
      fontSize: '14px'
    },
    icon: {
      color: theme.palette.primary.main
    },
    summary: {
      fontSize: '12px'
    }
  })
);