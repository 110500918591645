import { Backdrop, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useStyles } from "../shared/styles";
import { FC, useContext, useEffect, useState } from "react";
import { ReservationContext } from "../shared/state";
import { useActivity } from "./useActivity";
import { PageTitle } from "../complex/PageTitle";

import { ReactComponent as SkylandIcon } from "../../assets/Skyland.svg";
import { StatePreview } from "./StatePreview";
import { ResponsiveStatePreview } from "../complex/ResponsiveStatePreview";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useActivityBookingStyles } from "./styles";
import { useComplexStyles, useStatePreviewStyles } from "../complex/styles";

import { IMAGES_API_URL } from "../../environment";
import { DateTimeAtendees } from "../complex/DateTimeAtendees";
import { Line } from "../shared/components/Line";
import { ActivityConfirmBookingComponent } from "./ActivityConfirmBookingComponent";
import { useTranslation } from "react-i18next";
import Loading from "../../shared/components/Loading";

type Props = {};

export const ActivityBooking: FC<Props> = () => {
  const { t } = useTranslation('activity-booking');

  const classesShared = useStyles();
  const classes = useStatePreviewStyles();

  const classesComplex = useComplexStyles();
  const classesActivity = useActivityBookingStyles();

  const { mainActivityId } = useParams<{ mainActivityId: string }>();

  const { activity } = useActivity(
    mainActivityId
  );

  const { dispatch } = useContext(ReservationContext);
  useEffect(() => {
    dispatch({ type: "SET_ACTIVITY", data: activity });
  }, [activity, dispatch]);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <>
      <PageTitle
        resourceName={activity?.site?.name ?? ''}
        activityName={activity?.name ?? ''}
        onOpenPreview={() => setDialogOpen(true)}
        Icon={<SkylandIcon />}
        CartIcon={<ShoppingCartIcon />}
      />
      <div className={classesShared.descriptionContainer}>
        <Typography>
          {t("subtitle")}
        </Typography>
      </div>
      <div className={classesComplex.root}>
        <Card className={classesShared.dropInBookingCard}>
          <CardHeader
            title={
              <div className={classes.cardHeaderContainer}>
                <div>{t("cardTitle")}</div>
              </div>
            }
            className={classes.cardHeader}
          />
          <CardContent>
            {!!activity && (
              <div className={classesActivity.bookingFormContainer}>
                <div className={classesActivity.imageContainer}>
                  <img
                    src={`${IMAGES_API_URL}/${activity?.imageUrl}`}
                    alt="Activity"
                  />
                </div>
                <div className={classesActivity.dateTimeAttendeesContainer}>
                  <DateTimeAtendees minAtendees={1} isActivityBooking={true} />
                </div>
              </div>
            )}
            <Line />
            <ActivityConfirmBookingComponent bookButtonLabel={t('confirmation.bookButton')} />
          </CardContent>
        </Card>
        <ResponsiveStatePreview
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          StatePreview={StatePreview}
        />
      </div>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!activity}
      >
        <Loading />
      </Backdrop>
    </>
  );
};
