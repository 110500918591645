import moment from "moment";
import { DaySchedule } from "./day-schedule.model";
import { TimeSlot, Reservation, CapacityTimeSlot } from "../../booking/model/timeslot.model";

export class DateSchedule {
  private reservations: Array<Reservation> = new Array<Reservation>();
  private get lastBookableTime(): moment.Moment {
    return moment().subtract(15, "minutes");
  }
  constructor(private date: moment.Moment, private daySchedule: DaySchedule) {
    this.date = this.date.clone();
  }
  public setReservations(reservations: Array<Reservation>) {
    this.reservations = reservations;
  }

  public createTimeSlots(requestedDuration: number = 60): Array<TimeSlot> {
    var timetable = new Array<TimeSlot>();
    this.daySchedule.forEachTimeSlot(this.date, requestedDuration, (time) => {
      timetable.push(
        new TimeSlot(time, requestedDuration, this.daySchedule.referenceId)
      );
    });
    return timetable;
  }
  public hasCampaign(campaign: string): boolean {
    return (
      this.daySchedule &&
      this.daySchedule.priceInfos &&
      this.daySchedule.priceInfos.filter((x) => x.campaign === campaign).length >
        0
    );
  }
  public getVacantTimeSlots(
    requestedCapacity: number = 1,
    requestedDuration: number = 60
  ): Array<TimeSlot> {
    var timeslots = this.createTimeSlots(requestedDuration);
    var vacant = timeslots.filter((timeslot) => {
      if (this.lastBookableTime.isSameOrAfter(timeslot.start())) return false;
      var res = this.canAddReservation(
        new Reservation(
          timeslot.startTime,
          timeslot.duration,
          requestedCapacity,
          "",
          this.daySchedule.referenceId,
          this.daySchedule.tenantId,
          this.daySchedule.scheduleType
        )
      );
      return res;
    });
    return vacant;
  }
  public getDaySchedule(): DaySchedule {
    return this.daySchedule;
  }
  public addReservation(reservation: Reservation): boolean {
    reservation.dayScheduleType = this.daySchedule.scheduleType;
    if (this.canAddReservation(reservation)) {
      this.reservations.push(reservation);
      return true;
    }
    return false;
  }

  public canAddReservation(reservation: Reservation): boolean {
    if (this.lastBookableTime.isSameOrAfter(reservation.start())) return false;
    var peopleCount = this.countPeopleInTimeslot(reservation);
    var scheduleType = this.getDaySchedule().scheduleType;
    var sameCountDuration = reservation.duration;
    var sameCountTimeSlot = new TimeSlot(
      reservation.start(),
      sameCountDuration,
      this.daySchedule.referenceId
    );

    if(this.daySchedule.concurrentReservations === 0) {
      const canAdd = (peopleCount + reservation.count <= this.daySchedule.maxCapacity);
      return canAdd;
    }
    const sameCount = this.countSameTypeReservationsInTimeslot(sameCountTimeSlot);
    const res = (
      (sameCount + 1) <= this.daySchedule.concurrentReservations
        && reservation.count <= this.daySchedule.maxCapacity);
    return res;
  }

  public reservationCount(): number {
    return this.reservations.length;
  }
  private countPeopleInTimeslot(timeslot: TimeSlot): number {
    var reservationsInSlot = this.reservations.filter(
      (reservation) =>
        reservation.isIn(timeslot)
    );
    var interval = this.daySchedule.interval;
    var slots = Math.ceil(timeslot.duration / interval);
    var range = [...Array(slots).keys() as any];
    
    var counts = range.map((slot) => {
      var startTime = timeslot
        .start()
        .clone()
        .add(slot * interval, "minutes");
      var intervalSlot = new TimeSlot(
        startTime,
        interval,
        this.daySchedule.referenceId
      );
      var intervalReservations = reservationsInSlot
      .filter((reservation) => reservation.isIn(intervalSlot));
      return intervalReservations
        .map((reservation) => reservation.count)
        .reduce((prev, curr) => prev + curr, 0);
    });
    return counts.reduce((prev, curr) => Math.max(prev, curr), 0);
  }

  private countSameTypeReservationsInTimeslot(timeslot: TimeSlot): number {
    const filtered = this.reservations.filter((reservation) => {
      return (
        reservation.isIn(timeslot)
      );
    });
    const count = filtered.reduce((acc,curr)=>acc+(curr.count>=0?1:-1),0);
    return count;
  }

  private countSameTypeReservationsStartingSimultaneously(timeslot: TimeSlot): number {
    const filtered = this.reservations.filter((reservation) => {
      return reservation.count !==0 && reservation.start().isSame(timeslot.start(), 'minute');
    });
    const count = filtered.reduce((acc,curr)=>acc+(curr.count>=0?1:-1),0);
    return count;
  }

  public countCapacity(): Array<CapacityTimeSlot> {
    var vacant = this.createTimeSlots(this.daySchedule.interval)
      .map((timeslot) => {
        const count = this.countPeopleInTimeslot(timeslot);
        const res = new CapacityTimeSlot(
          timeslot.startTime,
          timeslot.duration,
          count,
          this.daySchedule.maxCapacity,
          this.daySchedule.referenceId
        );
        return res;
      })
      .reduce((acc: Array<CapacityTimeSlot>, res: CapacityTimeSlot) => {
        acc.push(res);
        return acc;
      }, []);
    return vacant;
  }

  public get referenceId(): string {
    return this.daySchedule.referenceId;
  } 
}
