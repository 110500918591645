import { Button } from "@mui/material";
import { FC } from "react";
import { useButtonStyles } from "../../complex/styles";

type Props = {
  className?: string;
  variant?: 'text'|'outlined'|'contained';
  onClick?: () => any;
  disabled?: boolean;
}

export const TextButton: FC<Props> = ({ variant='text', onClick = () => {}, disabled = false, className, children }) => {
  const classes = useButtonStyles();

  return (
    <Button variant={variant} className={`${classes.textButton} ${className ?? ''}`} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  )
}