import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { HeaderLogo } from '../shared/components/HeaderLogo';

import './styles.scss';

type Props = {
  children?: any;
}

export const AppLayout: React.FC<Props> = ({ children }) => {

  return (
    <>
      <HeaderLogo />
      <Box sx={{ display: 'flex' }}>
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          {children}
        </Container>
      </Box>
    </>
  );
}