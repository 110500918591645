import { Route } from "react-router-dom";
import { PartialUpdate } from "./PartialUpdate";
import { RefNumber } from "./RefNumber";
import { Vipps } from "./Vipps";

export const RefNumberRoutes = () => {
  return (
    <>
      <Route exact path="/:lang/ref-number">
        <RefNumber />
      </Route>
      <Route exact path="/:lang/ref-number-vipps">
        <RefNumber Vipps={Vipps} route="ref-number-vipps" />
      </Route>
      <Route exact path="/:lang/ref-number-update">
        <RefNumber PartialUpdate={PartialUpdate} route="ref-number-update" />
      </Route>
      <Route exact path="/:lang/ref-number/:refCode">
        <RefNumber />
      </Route>
      <Route exact path="/:lang/ref-number-vipps/:refCode">
        <RefNumber Vipps={Vipps} route="ref-number-vipps" />
      </Route>
      <Route exact path="/:lang/ref-number-update/:refCode">
        <RefNumber PartialUpdate={PartialUpdate} route="ref-number-update" />
      </Route>

      <Route exact path="/:lang/ref-number-allergies">
        <RefNumber PartialUpdate={PartialUpdate} route="ref-number-allergies" />
      </Route>
      <Route exact path="/:lang/ref-number-allergies/:refCode">
        <RefNumber PartialUpdate={PartialUpdate} route="ref-number-allergies" />
      </Route>
    </>
  );
};
