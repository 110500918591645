import { useEffect, useState } from "react";
import { BookableResource } from "../model/bookable-resource";
import { BookableResourceService } from "../services/bookable-resource.service";

export const useResource = (refId?: string) => {
  const [resource, setResource] = useState<BookableResource>();
  useEffect(() => {
    const resourceUrl = refId || "krsvag";
    const svc = new BookableResourceService();
    svc.getResource(resourceUrl).subscribe((r: BookableResource) => {
      setResource(r);
    });
  }, [refId]);
  return {resource};
}