import { DaySchedule } from "../../shared/model/day-schedule.model";
import {
  Observable,
} from "rxjs";
import { map } from "rxjs/internal/operators/map";
import { ObservableAxios } from "../../system/rxjs-axios";
import { SCHEDULING_API_URL } from "../../environment";
import { Activity } from "../model/activity.model";

export class ActivityService {
  private readonly base_schedule_url = SCHEDULING_API_URL
  private readonly activity_url =
    this.base_schedule_url + "/api/activities";

  constructor(private http: ObservableAxios) {}

  // public getActivityWithReservations(
  //   referenceId: string
  // ): Observable<Schedule> {
  //   return forkJoin([
  //     this.getDaySchedules(type, referenceId),
  //     this.getAllReservations(referenceId),
  //   ]).pipe(
  //     map((data) => {
  //       return new Schedule(moment(), data[0], referenceId, data[1]);
  //     })
  //   );
  // }

  public getRegularActivities(siteId?:string): Observable<Activity[]> {
    return this.http.get(`${this.activity_url}/regular-detailed?mainActivityId=${siteId}`).pipe(
      map((data: any) => {
        return data.map((d: any) => Activity.deSerialize(d));
      })
    );
  }

  public getActivities (
    activityIds: string[]
  ): Observable<Activity[]> {
    var params = new URLSearchParams();
    activityIds.forEach(activityId => params.append('activityId', activityId));

    return this.http.get(`${this.activity_url}/detailed`, {params}).pipe(
      map((data: any) => {
        return data.map((d: any) => Activity.deSerialize(d));
      })
    );
  }

  public getActivity (
    referenceId: string
  ): Observable<Activity> {
    var params = {
      params: {
      },
    };

    return this.http.get(`${this.activity_url}/${referenceId}`, params).pipe(
      map((data: any) => {
        var res =  Activity.deSerialize(data);
        return res;
      })
    );
  }
  
  public mapToWeekSchedule(
    ob: Observable<Array<DaySchedule>>
  ): Observable<Map<string, DaySchedule>> {
    return ob.pipe(
      map((daySchedules: Array<DaySchedule>) => {
        return daySchedules.reduce(
          (map: Map<string, DaySchedule>, daySchedule: DaySchedule) => {
            return daySchedule.weekDays.reduce(
              (map: Map<string, DaySchedule>, daynr: number) => {
                map.set(daynr + '', daySchedule);
                return map;
              },
              map
            );
          },
          new Map<string, DaySchedule>()
        );
      })
    );
  }

  
  // private mapReservations(m: Array<any>): Array<Reservation> {
  //   var reservationMap = m
  //     .map((x) => Reservation.mapReservation(x))
  //     .reduce((acc: Map<string, Reservation>, r: Reservation) => {
  //       var reservation = acc.get(r.reservationId);
  //       if (!reservation) {
  //         acc.set(r.reservationId, r);
  //       } else if (r.count < 0) {
  //         acc.delete(r.reservationId);
  //       } else if (r.count === 0) {
  //         reservation.checkedIn = true;
  //       }
  //       return acc;
  //     }, new Map<string, Reservation>());
  //   return Array.from(reservationMap.values());
  // }

  // private getAllReservations(
  //   referenceId: string
  // ): Observable<Array<Reservation>> {
  //   return this.http
  //     .get(this.reservations_url + `/${referenceId}`)
  //     .pipe(map((a: Array<any>) => this.mapReservations(a)));
  // }

}
