import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import BgSky from "../assets/bg_sky.png";

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  rootConfirm: {
    maxWidth: "40vw",
    margin: "auto",
  },
  rootInfo: {
    maxWidth: "80vw",
    margin: "auto",
  },
  rootDetails: {
    maxWidth: "50vw",
    margin: "auto",
  },
  cardHeader: {
    textAlign: "center",
  },
  divLeft: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  backgroundImage: {
    backgroundImage: `url("${BgSky}")`,
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    zIndex: "-2",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  label: {
    fontWeight: '700',
    fontSize: '15px'
  },
  smallText: {
    fontSize: '12px'
  },
  confirmationText: {
    fontSize: '15px',
    textAlign: 'center'
  },
  centerColumn: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
  },
  robotoThin: {
    fontFamily: 'Roboto, Thin',
    color: theme.palette.primary.main,
    fontWeight: '200',
  },
  robotoRegular: {
    fontFamily: 'Roboto, Thin',
    color: theme.palette.primary.main,
    fontWeight: '200',
  },
})
);