import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";

import { AppLayout } from "./AppLayout";
import { AppRoutes } from "./AppRoutes";
import { ThemeProvider } from "@mui/material";
import { theme } from "../react-theme";

export default function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AppLayout>
          <AppRoutes />
        </AppLayout>
      </ThemeProvider>
    </Router>
  );
}
