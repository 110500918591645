import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from '@mui/styles';
import * as React from "react";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
  },
});

type props = {
  size?: number;
  className?: string;
  sx?: any;
};

const Loading: React.FC<props> = ({ size, className="", sx }) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.root} ${className}`}>
      <CircularProgress size={size ? size : 100} sx={sx} />
    </Box>
  );
};

export default Loading;
