import { FC, useContext } from "react";
import { AlergiesField } from "./AlergiesField";
import { ComplexBookingContext } from "./state";

type Props = {};

export const Alergies: FC<Props> = () => {
  const { state, dispatch } = useContext(ComplexBookingContext);

  return (
    <AlergiesField
      alergies={state.alergies}
      selectedAlergies={state.selectedAlergies}
      onAlergiesChange={alergies => dispatch({ type: "SET_ALERGIES", data: alergies })}
      onSelectedAlergiesChange={selected => dispatch({ type: "SET_SELECTED_ALERGIES", data: selected })}
    />
  );
};
