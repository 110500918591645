/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardContent, CardHeader } from "@mui/material"
import { useStyles } from "./styles";
import { useEffect, useState } from "react";
import { PaymentReceiptStatus } from "./model/payment-receipt-status";
import Loading from "../shared/components/Loading";
import { FinalizeVippsResult, PaymentService } from "../shared/services/payment.service";
import { observableAxios } from "../system/rxjs-axios";
import { ReceiptDataVipps } from "./model/receipt-data-vipps";
import { isPaid } from "./model/payment-status";
import { useParams } from "react-router-dom";

export const PaymentReceipt = () => {
  const [paymentService] = useState(new PaymentService(observableAxios));

  const classes = useStyles();

  const [status, setStatus] = useState<PaymentReceiptStatus>();
  const [referenceNumber, setReferenceNumber] = useState("");

  const { orderId } = useParams<{ orderId?: string }>();

  useEffect(() => {
    if (!orderId) {
      console.error("Order id not found");
      setStatus(PaymentReceiptStatus.error);
      return;
    }

    paymentService.getReceiptDataVipps(orderId).subscribe(
      (receiptData: ReceiptDataVipps) => {
        gotReceiptData(receiptData);
      },
      (err) => {
        setStatus(PaymentReceiptStatus.error);
        console.error("Error getting receipt data: ", err);
      }
    );
  }, [orderId]);

  const gotReceiptData = (receiptData: ReceiptDataVipps) => {
    if (receiptData == null) {
      setStatus(PaymentReceiptStatus.error);
      return;
    }

    if (isPaid(receiptData.status)) {
      setStatus(PaymentReceiptStatus.finalized);
      return;
    }

    if (receiptData.isCallFinalizeForReceiptData()) {
      setStatus(PaymentReceiptStatus.finalizing);

      paymentService.finalize(orderId as string).subscribe(
        (finalizeResult: FinalizeVippsResult|null) => {
          gotFinalizeResult(finalizeResult);
        },
        (err) => {
          console.error("Error finalizing", err);
          setStatus(PaymentReceiptStatus.error);
        }
      );
    } else {
      // TODO: Is not paid and status indicates should not pay either. Investigate this case further?
      setStatus(PaymentReceiptStatus.error);
      console.error("Receipt data not paid and not finalize:", receiptData);
    }
  }

  const gotFinalizeResult = (finalizeResult: FinalizeVippsResult|null) => {
    if (
      finalizeResult != null && isPaid(finalizeResult.receiptData.status)
    ) {
      setStatus(PaymentReceiptStatus.finalized);
      setReferenceNumber(finalizeResult.receiptData.referenceNumber);
    } else {
      setStatus(PaymentReceiptStatus.error);
      console.error("Finalize not paid:", finalizeResult);
    }
  }

  if (status === PaymentReceiptStatus.loading) {
    return (
      <Card className={classes.root}>
        <CardHeader title="Henter data" />
        <CardContent>
          <Loading />
          <p>Henter data, ikke lukk vinduet.</p>
        </CardContent>
      </Card>
    );
  } else if (status === PaymentReceiptStatus.finalizing) {
    return (
      <Card className={classes.root}>
        <CardHeader title="Fullfører kjøp" />
        <CardContent>
          <Loading />
          <p>Vennligst ikke lukk vinduet mens kjøpet fullføres.</p>
        </CardContent>
      </Card>
    );
  } else if (status === PaymentReceiptStatus.finalized) {
    return (
      <Card className={classes.root}>
        <CardHeader title="Kvittering" />
        <CardContent>
          <Loading />
          <p>Booking med referansenummer <b>{referenceNumber}</b> er nå betalt.</p>
        </CardContent>
      </Card>
    );
  } else if (status === PaymentReceiptStatus.error) {
    return (
      <Card className={classes.root}>
        <CardHeader title="En feil oppstod" />
        <CardContent>
          <Loading />
          <p>Vennligst prøv igjen.</p>
        </CardContent>
      </Card>
    );
  }
  return <></>;
}