import { WaiverForm } from '../model/waiverform.model';
import { WaiverConfirmation } from '../model/waiver-confirmation.model';
import { Observable, map } from 'rxjs';
import { WAIVER_API_URL } from '../../environment';
import { ObservableAxios } from '../../system/rxjs-axios';
import { Guid } from '../../system/guid';
import moment from 'moment';
import { SignatureLogService } from '../../signature-log/signature-log.service';

export class WaverFromService {

  getFiltered(filter: string): any {
    return this.http.get(`${this.waiverApi}/filter?filterName=${filter}`);
  }

  private readonly waiverApi = WAIVER_API_URL + '/api/wavercontracts';

  constructor(private http: ObservableAxios, private signatureService: SignatureLogService) { }

  public save(waiver: WaiverForm): any {
    return this.http
        .put(`${this.waiverApi}/${waiver.id}`, waiver);
  }

  public confirmWaiver(waiverConfirmation:WaiverConfirmation){
    return this.http
      .post(`${this.waiverApi}/waiver_confirm`, waiverConfirmation).pipe(
        map(x=>{
        return x as WaiverConfirmation;
      }));
  }

  public post(waiver: WaiverForm): any {
    return this.http
        .post(`${this.waiverApi}`,waiver);
  }

  get(): any {
    return this.http.get(this.waiverApi);
  }

  getOne(id:string): any {
    return this.http.get(`${this.waiverApi}/${id}`);
  }
  search(phone:string):Observable<Array<WaiverForm>>{
    return this.http.get(`${this.waiverApi}/search?phone=${phone}`).pipe(
      map((data:Array<any>)=>data.map(obj=>obj  as WaiverForm))
    );
  }
  confirmWaiverExist(phone:string):Observable<any>{
    return this.http.get(`${this.waiverApi}/${phone}/confirm`);
  }
  requestConfirmation(documentId: string, mobile: string, tenantId: string, code?: string) {
    const confirmationRequest = {
      id: Guid.empty().toString(),
      documentId,
      logEntry: { mobile, code },
      occuredTime: moment().utc(),
      tenantId: tenantId,
    };

    return this.signatureService.requestConfirmation(confirmationRequest);
  }

}
