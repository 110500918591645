import { Button, Card, CardHeader, CardActions, CardContent, MenuItem, Theme } from '@mui/material'
import { Link } from 'react-router-dom';
import { DropdownMenu } from '../shared/components/DropdownMenu';
import { useEffect, useState } from 'react';
import { BookableResourceService } from '../shared/services/bookable-resource.service';
import { BookableResource } from '../shared/model/bookable-resource';
import { createStyles, makeStyles } from '@mui/styles'

import BgSky from '../assets/bg_sky.png';
import Bookingbilde from '../assets/bookingbilde.jpg';
import Bursdagsbilde from '../assets/bursdagsbilde.jpg';
import { useNavigate } from '../booking/shared/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        flexWrap: 'wrap',
        flexDirection: 'column',
      }
    },
    cardContainer: {
      maxWidth: '250px',
      marginBottom: '1em'
    },
    imageContainer: {
      maxWidth: '250px'
    }
  })
);

export const StartPage = () => {
  const classes = useStyles();

  const version = '1.2';
  
  const navigate = useNavigate();

  const [bookableResources, setBookableResources] = useState<BookableResource[]>([]);

  useEffect(() => {
    const svc = new BookableResourceService();
    svc.getResources().subscribe(setBookableResources);
  }, []);

  return (
    <>
      <div
        className={classes.root}
      >
        <div>
          <Card className={classes.cardContainer}>
            <CardHeader title="waiver/egenerklæring">
            </CardHeader>
            <img src={BgSky} alt="Shiba Inu" className={classes.imageContainer} />
            <CardContent>
              <p>
                Egenerklæring må fylles ut før man hopper! Alle våre besøkende er
                pålagt å signere egenerklæring før de kan hoppe i parken.
              </p>
            </CardContent>
            <CardActions>
              <Button
                component={Link}
                to="/egenerklering"
              >
                Signere egenerklæring
              </Button>
            </CardActions>
          </Card>
        </div>

        <div>
          <Card className={classes.cardContainer}>
            <CardHeader title="Book hoppetime">
            </CardHeader>
            <img src={Bookingbilde} alt="book hoppetime" className={classes.imageContainer} />
            <CardContent>
              <p>
                Drop-in eller book hoppetime! Book hoppetime få garantert plass!
              </p>
            </CardContent>
            <CardActions>
              <DropdownMenu
                label="Book hoppetime"
              >
                {bookableResources.map(r => (
                  <MenuItem key={r.id} onClick={() => navigate(`/book/${r.prittyUrl}`)}>{r.name}</MenuItem>
                ))}
              </DropdownMenu>
            </CardActions>
          </Card>
        </div>
        <div>
          <Card className={classes.cardContainer}>
            <CardHeader title="App versjon">
            </CardHeader>
            <CardContent>
              <p>
                {version}
              </p>
            </CardContent>
            <CardActions>
              <Button onClick={() => window.location.reload()}>
                Oppdater app
              </Button>
            </CardActions>
          </Card>
        </div>
        <div>
          <Card className={classes.cardContainer}>
            <CardHeader title="Bursdags booking">
            </CardHeader>
            <img src={Bursdagsbilde} alt="Book bursdag" className={classes.imageContainer} />
            <CardContent>
              <p>
                Fra 214 kr. per barn ! 2 timer med feiring Hopp og moro hvor man
                alltid har instruktør
              </p>
            </CardContent>
            <CardActions>
            <DropdownMenu
                label="Book Bursdag"
              >
                {bookableResources.map(r => (
                  <MenuItem key={r.id} onClick={() => navigate(`/booking/${r.prittyUrl}`)}>{r.name}</MenuItem>
                ))}
              </DropdownMenu>
            </CardActions>
          </Card>
        </div>
      </div>
    </>
  );
}