import { FC } from "react";
import { useTextStyles } from "../../complex/styles";

type Props = {
  className?: string;
  onClick?: (e: any) => any
}

export const ColoredText: FC<Props> = ({ className, children, onClick = () => {} }) => {
  const classes = useTextStyles();

  return (
    <span className={`${classes.coloredText} ${className ?? ''}`} onClick={onClick}>
      {children}
    </span>
  )
}