import { IconButton } from "@mui/material";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColoredText } from "../shared/components/ColoredText";
import { ReservationContext } from "../shared/state";
import { CartIconWithBubble } from "./PageTitle";
import { ResponsiveStatePreview } from "./ResponsiveStatePreview";
import { calcTotalPrice, calcUnitPrice, ComplexBookingContext } from "./state";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

type Props = {};

export const TotalPrice: FC<Props> = () => {
  const { t } = useTranslation("group-booking");
  const { state: complexState } = useContext(ComplexBookingContext);
  const { state: reservationState } = useContext(ReservationContext);
  const totalPrice = calcTotalPrice(reservationState, complexState);

  return <>{totalPrice || 0},- kr.</>;
};

export const TotalPriceDisplay: FC<Props> = () => {
  const { t } = useTranslation("group-booking");
  return (
    <span>
      <ColoredText>{t("summary.total")}: </ColoredText>
      <TotalPrice />
    </span>
  );
};


export const TotalPriceStatusDisplay: FC<Props> = () => {
  // TODO add link to cart popup
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { t } = useTranslation("group-booking");
  return (
    <div>
      <TotalPriceDisplay/>
      <IconButton
        onClick={()=>setDialogOpen(!dialogOpen)}
      >
        <ShoppingCartIcon />
      </IconButton>
      <ResponsiveStatePreview
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          dialogOnly={true}
        />
    
    </div>
  );
};