import { Button, Menu } from "@mui/material";
import React, { FC, useState } from "react";

type Props = {
  label: string;
  children: any;
}

export const DropdownMenu: FC<Props> = ({ label, children }) => {
  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      const childClick = (child as any)?.props?.onClick;
      return React.cloneElement(child, { onClick: () => { handleClose(); typeof childClick === "function" && childClick() }  } as any);
    }
    return child;
  });

  return (
    <>
      <Button
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {childrenWithProps}
      </Menu>
    </>
  );
}