import moment, { duration, Moment } from "moment";
import { createContext, Dispatch, useContext, useReducer } from "react";
import { ProductItem } from "../../shared/model/product";
import { createChainOfHandlersFor } from "../../shared/utils/chain";
import {
  Activity,
  ActivityPriceModel,
  ActivityType,
} from "../model/activity.model";
import { ReservationContext, ReservationState } from "../shared/state";
import { StatePreview } from "./StatePreview";

export const N_OF_STEPS = 5;

export type ComplexBookingState = {
  activity: Activity | null;
  activities: Activity[];
  defaultSubActivities: Activity[];
  selectedItems: ProductItem[];
  alergies: string;
  selectedAlergies: string[];
  alergiesValid: boolean;
  prPersonPrice: number;
  prOrderPrice: number;
  step: number;
  expanded: boolean[];
  makeReservation: boolean;
  code: string;
  isLoadingActivities: boolean;
  isLoadingProducts: boolean;
  activitiesUnitPrice: number;
  activityPrices: { [key: string]: number };
  selectedDuration: number;
  useMainActivityCostOnly: boolean;
  reservationState: ReservationState | null;
  defaultActivityDuration: number;
};

const initialState: ComplexBookingState = {
  activity: null,
  activities: [],
  defaultSubActivities: [],
  selectedItems: [],
  alergies: "",
  selectedAlergies: [],
  alergiesValid: false,
  prPersonPrice: 0,
  prOrderPrice: 0,
  step: 0,
  expanded: Array.from({ length: N_OF_STEPS }, (_) => false),
  makeReservation: false,
  code: "",
  isLoadingActivities: true,
  isLoadingProducts: true,
  activitiesUnitPrice: 0,
  activityPrices: {},
  selectedDuration: 0,
  useMainActivityCostOnly: false,
  reservationState: null,
  defaultActivityDuration: 0,
};

type Action = {
  type: string;
  data: any;
};

const alergiesValidityHandler = (
  state: ComplexBookingState
): ComplexBookingState => {
  const { alergies } = state;
  return {
    ...state,
    alergiesValid: alergies.length <= 150,
  };
};
// Need count here to calculate correct
const productPriceHandler = (
  state: ComplexBookingState
): ComplexBookingState => {
  const { selectedItems } = state;
  return {
    ...state,
    prPersonPrice: selectedItems
      .filter((item) => !item.prOrder)
      .reduce((acc, item) => acc + item.price, 0),
    prOrderPrice: selectedItems
      .filter((item) => item.prOrder)
      .reduce((acc, item) => acc + item.price, 0),
  };
};
const activityPriceHandler = (
  state: ComplexBookingState
): ComplexBookingState => {
  let activityPrices: { [key: string]: number } = {};
  if (!state.activity || state.useMainActivityCostOnly) return state;
  const priceAccumulator = (acc: number, act: Activity) => {
    const priceInfo = act.getPriceInfoForSubActivity(
      moment(state?.reservationState?.date)
    );
    if (priceInfo) {
      activityPrices[act.id] = priceInfo.price;
      acc += priceInfo.price;
    }
    return acc;
  };
  let defaultActivitiesUnitPriec =
    state?.defaultSubActivities?.reduce(priceAccumulator, 0) ?? 0;
  let activitiesUnitPrice = state?.activities?.reduce(priceAccumulator, 0) ?? 0;
  return {
    ...state,
    activitiesUnitPrice: activitiesUnitPrice + defaultActivitiesUnitPriec,
    activityPrices,
  };
};
const selectedTimeCalculator = (
  state: ComplexBookingState
): ComplexBookingState => {
  const selectedDuration =
    state?.activities?.length <= 1
      ? 0
      : (state?.activities?.length - 1) * 30;
  return { ...state, selectedDuration: selectedDuration };
};
const defaultActivityTimeCalculator = (
  state: ComplexBookingState
): ComplexBookingState => {
  const nonDefaultActivities =state?.activities.filter(
    (x) => x.id != state.activity?.defaultSubActivityId
  );
  const selectedNonDefaultDuration = nonDefaultActivities?.length <= 1
      ? 0
      : (nonDefaultActivities?.length - 1) * 30;
  const defaultActivityDuration = state?.reservationState?.priceInfo
    ? state?.reservationState?.priceInfo?.duration - selectedNonDefaultDuration
    : 0;
  return { ...state, defaultActivityDuration: defaultActivityDuration };
};
const chain = createChainOfHandlersFor<ComplexBookingState>([
  productPriceHandler,
  activityPriceHandler,
  alergiesValidityHandler,
  selectedTimeCalculator,
  defaultActivityTimeCalculator,
]);

const reducer = (state: any, action: Action): ComplexBookingState => {
  switch (action.type) {
    case "ADD_PRODUCT_ITEM":
      return chain({
        ...state,
        selectedItems: [...state.selectedItems, action.data],
      });
    case "REMOVE_PRODUCT_ITEM":
      return chain({
        ...state,
        selectedItems: [
          ...state.selectedItems.filter(
            (pItem: ProductItem) => !(pItem.id === action.data.id)
          ),
        ],
      });
    case "ADD_ACTIVITY":
      return chain({
        ...state,
        activities: [
          ...state.activities.filter(
            (activity: Activity) => activity?.id !== action.data?.id
          ),
          action.data,
        ],
      });
    case "SET_DEFAULT_ACTIVITY":
      var defaultSubActivity = action.data.defaultSubActivity;
      var defaultActivityCount = Math.ceil(
        action.data.defaultActivityDuration / 30
      );
      var defaultSubActivities = defaultSubActivity
        ? Array(defaultActivityCount).fill(defaultSubActivity)
        : state.defaultSubActivities;
      return chain({ ...state, defaultSubActivities });
    case "REMOVE_ACTIVITY":
      return chain({
        ...state,
        activities: [
          ...state.activities.filter(
            (activity: Activity) => activity?.id !== action.data?.id
          ),
        ],
      });
    case "SET_ALERGIES":
      return chain({
        ...state,
        alergies: action.data,
      });
    case "SET_SELECTED_ALERGIES":
      return chain({
        ...state,
        selectedAlergies: action.data,
      });
    case "SET_STEP":
      return chain({
        ...state,
        step: action.data,
      });
    case "CLOSE_STEP": {
      const s = action.data;
      state.expanded[s] = false;
      return chain({
        ...state,
        expanded: [...state.expanded],
      });
    }
    case "TOGGLE_STEP": {
      const s = action.data;
      const expanded = [...state.expanded];
      expanded[s] = !expanded[s];
      return chain({
        ...state,
        expanded,
      });
    }
    case "SET_CODE": {
      return chain({
        ...state,
        code: action.data,
      });
    }
    case "MAKE_RESERVATION": {
      return chain({
        ...state,
        makeReservation: true,
      });
    }
    case "RESET_MAKE_RESERVATION": {
      return chain({
        ...state,
        makeReservation: false,
      });
    }
    case "SET_LOADING_ACTIVITIES": {
      return chain({
        ...state,
        isLoadingActivities: action.data,
      });
    }
    case "SET_LOADING_PRODUCTS": {
      return chain({
        ...state,
        isLoadingProducts: action.data,
      });
    }
    case "SET_RESERVATION_STATE": {
      return chain({ ...state, reservationState: action.data });
    }
    case "SET_ACTIVITY": {
      return chain({
        ...state,
        activity: action.data,
        useMainActivityCostOnly:
          action?.data?.priceModel ===
          ActivityPriceModel.UseMainActivityCostOnly,
      });
    }
    case "SET_ACTIVITY": {
      return chain({
        ...state,
        activity: action.data,
      });
    }
    default:
      return state;
  }
};

export const useComplexBookingReducer = (
  reservationState: ReservationState
) => {
  return useReducer(reducer, {
    ...initialState,
    reservationState: reservationState,
  });
};

type ComplexBookingContextType = {
  state: ComplexBookingState;
  dispatch: Dispatch<Action>;
};

export const ComplexBookingContext = createContext<ComplexBookingContextType>({
  state: initialState,
  dispatch: () => {},
});

export const getSelectedCoversDuration = (
  reservationState: ReservationState,
  complexState: ComplexBookingState
) =>
  (reservationState?.priceInfo?.duration ?? 0) <= complexState.selectedDuration;
export const getSelectedDuration = (
  reservationState: ReservationState,
  complexState: ComplexBookingState
) =>
  complexState.selectedDuration +
  (!getSelectedCoversDuration(reservationState, complexState) &&
  reservationState.hasDefaultSubActivity
    ? 30
    : 0);
export const getNeedsLongerDuration = (
  reservationState: ReservationState,
  complexState: ComplexBookingState
) =>
  reservationState.priceInfo &&
  reservationState.priceInfo.duration <
    getSelectedDuration(reservationState, complexState);

export const calcTotalPrice = (
  reservationState: ReservationState,
  complexState: ComplexBookingState
) => {
  const count = calcCount(reservationState, complexState);
  const bookedCount = reservationState.bookedCount;
  const totalPrice =
    reservationState.totalPrice +
    complexState.prOrderPrice +
    complexState.prPersonPrice * count +
    complexState.activitiesUnitPrice * bookedCount;
  return totalPrice;
};

export const calcUnitPrice = (
  reservationState: ReservationState,
  complexState: ComplexBookingState
) => {
  const count = calcCount(reservationState, complexState);
  const totalPrice = calcTotalPrice(reservationState, complexState);
  return totalPrice / count;
};

export const calcCount = (
  reservationState: ReservationState,
  complexState: ComplexBookingState
) => {
  return reservationState.count || reservationState.bookedCount;
};
