import { ReactComponent as LogoSvg } from '../../assets/header-logo.svg';

export const HeaderLogo = () => {
  return (
    <div className="header-logo-wrapper">
      <a href="http://www.skyland.no" rel="home" className="header-logo">
        <LogoSvg />
      </a>
    </div>
  );
};
