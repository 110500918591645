import moment from "moment";
import { createContext, Dispatch, useReducer } from "react";
import { Guid } from "../system/guid";
import { Person } from "./Person.model";

export type Details = {
  id: string;
  contactPerson: Person,
  children: any[],
  email: string;
  mobile: string;
  advertising: boolean;
  referenceId:string;
  tenantId: string;
  isValid: boolean;
  signingdate: moment.Moment;
  documentId:string;
}

const initialState: Details = {
  id: "",
  mobile: "",
  email: "",
  advertising: false,
  children: [],
  contactPerson: {
    firstname: "",
    lastname: "",
    birth: moment().utc()
  },
  isValid: false,
  referenceId: "",
  tenantId: "",
  signingdate: moment().utc(),
  documentId: "",
};

type Action = {
  type: string;
  data: any;
}

const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case "SET_DETAILS":
      const id = Guid.newGuid().toString();
      return {
        ...state,
        ...action.data,
        id,
        documentId: id
      };
    case "SET_MOBILE":
      return {
        ...state,
        mobile: action.data
      };
    case "SET_VALID":
      return {
        ...state,
        isValid: action.data,
      }
    default:
      return state;
  }
}

export const useDetailsReducer = () => useReducer(reducer, initialState);

type DetailsContextType = {
  state: Details;
  dispatch: Dispatch<Action>;
};

export const DetailsContext = createContext<DetailsContextType>({ state: initialState, dispatch: () => {} });