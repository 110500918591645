import { Route } from "react-router-dom";
import { ComplexBooking } from "./ComplexBooking";
import { useComplexBookingReducer, ComplexBookingContext } from "./state";
import { ReservationContext, useReservationReducer } from "../shared/state";
import { DetailsContext, useDetailsReducer } from "../../details/state";
import { useContext, useEffect } from "react";

export const ComplexBookingState = (props:any) => {
  const { state: reservationState } = useContext(ReservationContext);
  const [complexBookingState, dispatchComplex] = useComplexBookingReducer(reservationState);
  useEffect(() => {
    dispatchComplex({ type: 'SET_RESERVATION_STATE', data: reservationState });
  }, [reservationState]);
  return (
    <>
      
        <ComplexBookingContext.Provider value={{ state: complexBookingState, dispatch: dispatchComplex }}>
          {props.children}
        </ComplexBookingContext.Provider>      
    </>
  );
}

export const ComplexBookingRoutes = () => {

  const [reservationState, dispatchReservation] = useReservationReducer();

  
  const [detailsState, dispatchDetails] = useDetailsReducer();

  return (
    <>
      <ReservationContext.Provider value={{ state: reservationState, dispatch: dispatchReservation }}>
        <ComplexBookingState>
          <DetailsContext.Provider value={{ state: detailsState, dispatch: dispatchDetails }} >
            <Route exact path="/:lang/booking/:mainActivityId">
              <ComplexBooking />
            </Route>
          </DetailsContext.Provider>
        </ComplexBookingState>
        
      </ReservationContext.Provider>
      
    </>
  );
}
