/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { take } from "rxjs";
import { observableAxios } from "../../system/rxjs-axios";
import { Activity } from "../model/activity.model";
import { ActivityService } from "./activity.service";

export const useAllActivities = (siteId?:string) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activities, setActivities] = useState<Activity[]>([]);
  useEffect(() => {
    const svc = new ActivityService(observableAxios);
    svc.getRegularActivities(siteId)
    .pipe(take(1))
    .subscribe((r: Activity[]) => {
      setActivities(r);
      setIsLoading(false);
    });
  }, []);
  return { activities, isLoading };
}

export const useActivities = (activityIds: string[]) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activities, setActivities] = useState<Activity[]>([]);
  useEffect(() => {
    if(!activityIds.length) {
      setIsLoading(false);
      return;
    }
    const svc = new ActivityService(observableAxios);
    svc.getActivities(activityIds)
    .pipe(take(1))
    .subscribe((r: Activity[]) => {
      setActivities(r);
      setIsLoading(false);
    });
  }, [activityIds.length]);
  return { activities, isLoading};
}

export const useActivity = (refId: string | undefined) => {
  const [activity, setActivity] = useState<Activity>();
  useEffect(() => {
    const svc = new ActivityService(observableAxios);
    refId && svc.getActivity(refId)
    .pipe(take(1))
    .subscribe((r: Activity) => {
      setActivity(r);
    });
  }, [refId]);
  return {activity};
}