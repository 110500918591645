import * as moment from "moment";
import { DaySchedule, PriceInfo } from "../../shared/model/day-schedule.model";
import { TimeSlot, Reservation, CapacityTimeSlot } from "./timeslot.model";
import { DateSchedule } from "../../shared/model/date-schedule.model";
import { CalendarTool } from "../../system/calendartool";

export class Schedule {
  getTenantId(): string {
    var randomDaySchedule = this.getFirstDaySchedule();
    return randomDaySchedule.tenantId;
  }
  private getFirstDaySchedule(): DaySchedule {
    return this.weekSchedule.values().next().value;
  }

  getReservation(timeSlot: TimeSlot, price: number, count: number): Reservation|null {
    return this.createReservation({
      timeSlot,
      price,
      count,
    });
  }

  createReservation(values: { timeSlot: TimeSlot; price: any; count: number }) {
    const { timeSlot, price, count } = values;
    var daySchedule = this.getFirstDaySchedule();
    return new Reservation(
      timeSlot.startTime,
      timeSlot.duration,
      count,
      "",
      timeSlot.referenceId,
      daySchedule.tenantId,
      price,
      daySchedule.scheduleType
    );
  }
  private exceptionsMap: Map<string, DaySchedule> = new Map<
    string,
    DaySchedule
  >();
  private weekSchedule: Map<string, DaySchedule> = new Map<string, DaySchedule>();
  private monthSchedule: Map<string, DateSchedule> = new Map<string, DateSchedule>();
  constructor(
    date: moment.Moment,
    private daySchedules: Array<DaySchedule>,
    public readonly referenceId: string,
    private reservations: Array<Reservation> = [],
    private concurentReservations?: number
  ) {
    this.build(date);
  }

  public build(date: moment.Moment){
      var sortdSchedules =  this.daySchedules?.sort((a: DaySchedule, b: DaySchedule) => {
        return a.getSortSize() - b.getSortSize();
      })
      this.weekSchedule = sortdSchedules?.reduce((map: Map<string, DaySchedule>, daySchedule: DaySchedule) => {
        if(this.concurentReservations)
          daySchedule.concurrentReservations = this.concurentReservations;
        if (!daySchedule.ValidTo && !daySchedule.ValidFrom 
          || !daySchedule.ValidTo && daySchedule.ValidFrom
          || !daySchedule.ValidFrom && daySchedule.ValidTo && daySchedule.ValidTo.isAfter(date)) {
          return daySchedule.weekDays.reduce(
            (map: Map<string, DaySchedule>, daynr: number) => {
              map.set(daynr.toString(), daySchedule);
              return map;
            },
            map
          );
        } else {
          var validDates = daySchedule.validDates();
          validDates.reduce((xmap, date) => {
            var dateString = CalendarTool.formatDate(date);
            xmap.set(dateString, daySchedule);
            return xmap;
          }, this.exceptionsMap);
        }
        return map;
      }, new Map<string, DaySchedule>());
    this.monthSchedule = this.buildMonthSchedule(date);
  }

  public useIncludeVr(include: boolean) {
    this.daySchedules.forEach((daySchedule: DaySchedule) => {
      daySchedule.useIncludeVr(include);
    });
  }
  public addReservation(reservation: Reservation): boolean {
    var dateSchedule = this.getDateSchedule(reservation.start());
    if (!dateSchedule || !dateSchedule.canAddReservation(reservation))
      return false;
    return dateSchedule.addReservation(reservation);
  }

  public canAddReservation(reservation: Reservation): boolean {
    var dateSchedule = this.getDateSchedule(reservation.start());
    if (!dateSchedule) return false;
    return dateSchedule.canAddReservation(reservation);
  }
  public setReservations(
    date: moment.Moment,
    reservations: Array<Reservation>
  ) {
    var dateSchedule = this.getDateSchedule(date);
    if (!dateSchedule) return;
    dateSchedule.setReservations(reservations);
  }
  public hasCampaign(date: moment.Moment, campaign: string): boolean {
    var dateSchedule = this.getDateSchedule(date);
    return !!(dateSchedule && dateSchedule.hasCampaign(campaign));
  }
  public getVacantTimeSlots(
    date: moment.Moment,
    requestedCapacity: number = 1,
    requestedDuration?: number
  ): Array<TimeSlot>|null {
    var dateSchedule = this.getDateSchedule(date);
    if (!dateSchedule) return null;
    if (!requestedDuration) {
      return []; // TODO Find usecase for this. And solve what default should be or other solution.
      // requestedDuration =
      //   dateSchedule.getDaySchedule().scheduleType === DayScheduleTypes.VR
      //     ? 15
      //     : 60;
    }
    return dateSchedule.getVacantTimeSlots(
      requestedCapacity,
      requestedDuration
    );
  }

  public getPriceInfos(date: moment.Moment): Array<PriceInfo>|null {
    var dateSchedule = this.getDateSchedule(date);
    if (!dateSchedule) return null;
    const daySchedule = dateSchedule.getDaySchedule();
    if (!daySchedule?.priceInfos) return null;
    return daySchedule.priceInfos;
  }
  public getIndexedReservations(): Map<string, Array<Reservation>> {
    return this.reservations.reduce(
      (map: Map<string, Array<Reservation>>, reservation: Reservation) => {
        const dayKey = reservation.start().format("DD.MM.YYYY");
        if (!map.has(dayKey)) {
          map.set(dayKey, new Array<Reservation>());
        }
        map.get(dayKey)?.push(reservation);
        return map;
      },
      new Map<string, Array<Reservation>>()
    );
  }
  public countCapacity(): Map<string, Array<CapacityTimeSlot>> {
    var capacitys = new Map<string, Array<CapacityTimeSlot>>();
    this.monthSchedule.forEach((dateSchedule: DateSchedule, key: string) => {
      capacitys.set(key, dateSchedule.countCapacity());
    });
    return capacitys;
  }
  private getDateSchedule(date: moment.Moment): DateSchedule|null {
    const dayKey = CalendarTool.formatDate(date);
    var refid = "18a80ba1-e76a-4387-a4bb-98cfb6b22452";

    var schedule = this.monthSchedule.get(dayKey) ?? null;
    if(schedule?.referenceId.toLocaleLowerCase() === refid || this.referenceId.toLocaleLowerCase() === refid){
      console.log("DateSchedule: ", schedule);
    }
    return schedule;
  }
  private buildMonthSchedule(date: moment.Moment): Map<string, DateSchedule> {
    var reservations = this.getIndexedReservations();

    var daysInMonth = 6 * 4 * 7;
    const ranged = Array.from(Array(daysInMonth).keys());

    var dateRange = ranged.map((days) => date.clone().add(days, "days"));
    var res = dateRange.reduce(
      (map: Map<string, DateSchedule>, currentDate: moment.Moment) => {
        const dateKey = currentDate.format("DD.MM.YYYY");
        if(dateKey === "18.02.2024"){
          console.log("Date: ", dateKey);
        }
        const dateSchedule = this.buildDateSchedule(currentDate);
        if (dateSchedule == null) return map;
        
        if (reservations.has(dateKey)) {
          dateSchedule.setReservations(reservations.get(dateKey) as Reservation[]);
        }
        map.set(dateKey, dateSchedule);
        return map;
      },
      new Map<string, DateSchedule>()
    );
    return res;
  }

  private buildDateSchedule(currentDate: moment.Moment): DateSchedule|null {
    var dateString = CalendarTool.formatDate(currentDate);
    var daySchedule = this.exceptionsMap.get(dateString);
    if (daySchedule == null) {
      var dayofWeek = currentDate.weekday();
      const dayOfWeek = dayofWeek.toString();
      daySchedule = this.weekSchedule?.get(dayOfWeek);
      if (!daySchedule || !daySchedule.isValidOn(currentDate)) {
        return null;
      }
    }
    const dateSchedule = new DateSchedule(currentDate.clone(), daySchedule);
    return dateSchedule;
  }
}
