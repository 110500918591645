import { Observable, map } from 'rxjs';
import {Site} from '../model/site';
import { ObservableAxios } from '../../system/rxjs-axios';
import { ENTITY_API_URL } from '../../environment';

export class SiteService {
  private readonly base_url;
  private readonly sites_url;
  constructor(private http: ObservableAxios) { 
      this.base_url = ENTITY_API_URL;
      this.sites_url = this.base_url + '/api/sites';
  }
  public getSites(): Observable<Array<Site>> {
    return this.http.get(`${this.sites_url}`).pipe(map((data: Array<any>)=>{
      return data.map(data => {
        return Site.deSerialize(data);
      });
    }));
  }
  public getPublicSites(type:string,resourceId:string): Observable<Array<Site>> {
    return this.http.get(`${this.sites_url}/public/${type}/${resourceId}`).pipe(map((data: Array<any>)=>{
      return data.map(data => {
        return Site.deSerialize(data);
      });
    }));
  }
}
