import axios from "axios";
import { useEffect, useState } from "react";
import { WAIVER_RULES_API_URL } from "../../environment";

export function useWaiverRules(language: string = 'no') {
  const [rules, setRules] = useState([]);

  useEffect(() => {
    axios.get(`${WAIVER_RULES_API_URL}/api/waiverrules/language/${language}`)
    .then(resp => {
      setRules(resp?.data?.rules ?? []);
    })
    .catch(err => console.error(err));
  }, [language]);

  return { rules };
}