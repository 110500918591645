import { FC } from "react";
import { useImageStyles } from "../../complex/styles";
import { Badge } from "./Badge";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

type Props = {
  selected?: boolean;
  onChange?: (v: boolean) => any;
  imageUrl?: string;
  label?: string;
  buttonLabel?: string;
}

export const SelectableImage: FC<Props> = ({
  selected = false,
  onChange = () => {},
  imageUrl = "",
  label = "",
  buttonLabel = ""
}) => {
  const classes = useImageStyles({ selected, imageUrl });

  return (
    <div className={classes.selectableImage} onClick={() => onChange(!selected)}>
      <label>{label}</label>
      <Badge grayedOut={!selected}>
        <div>{buttonLabel} {selected ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankIcon />}</div>
      </Badge>
    </div>
  )
}