import { BookableResource } from '../model/bookable-resource';
import { SiteService } from './site.service';
import { Observable, Subject, of, map } from 'rxjs';
import { TENANT_ID } from '../../environment';
import { Site } from '../model/site';
import { observableAxios } from '../../system/rxjs-axios';

export class BookableResourceService {
  private serviceService: SiteService = new SiteService(observableAxios);

  private resources: {[key: string]: BookableResource} = {}

  public getResource(prittyUrl:string): Observable<BookableResource>{
    var subject = new Subject<BookableResource>();
    if(this.resources[prittyUrl]){
      return of(this.resources[prittyUrl]);
    }
    this.getResources().subscribe((_: any)=>{
      subject.next(this.resources[prittyUrl]);
    });
    return subject.asObservable();
  }

  
  //TODO Add Activeties to Bookable resources??
  
  getResources(): Observable<Array<BookableResource>> {
    var resources: Observable<Array<BookableResource>> = 
      this.serviceService.getPublicSites("AmusementPark", TENANT_ID).pipe(map((x: Site[]) => {
        return x.map(y => {
          var o = BookableResource.deSerialize(y);
          this.resources[o.prittyUrl] = o;
          return o;
        });
      }));
    return resources;
  }
}
