import { MenuItem, Select } from "@mui/material";
import { FC } from "react";
import { useInputStyles } from "../../complex/styles";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

type Props = {
  label?: JSX.Element | string;
  defaultValueLabel?: string;
  value?: string|number;
  onChange?: (v: string|number) => any;
  options: { label: string | number; value: string | number }[];
  disabled?: boolean;
};

export const SelectInput: FC<Props> = ({
  label,
  defaultValueLabel = "Select",
  options,
  value,
  onChange,
  disabled = false
}) => {
  const classes = useInputStyles();

  return (
    <div className={classes.inputContainer}>
      <label>{label}</label>
      <Select
        variant="standard"
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        className={`${classes.selectField} ${classes.field}`}
        displayEmpty={true}
        disableUnderline
        IconComponent={KeyboardArrowDownOutlinedIcon}
        MenuProps={{
          style: {
            maxHeight: 300,
            color: "#fff"
          },
        }}
        error={true}
        disabled={disabled}
      >
        <MenuItem value="">
          <em>{defaultValueLabel}</em>
        </MenuItem>
        {options.map((o, i: number) => (
          <MenuItem sx={{ color: '#fff' }} key={`${i}${o.value}${o.label}`} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
