import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useInputStyles } from "../../complex/styles";
import noLocale from "date-fns/locale/nb";

type Props = {
  label?: JSX.Element;
  onChange?: (date: Date) => any;
};

export const DateInput: FC<Props> = ({ label, onChange }) => {
  const classes = useInputStyles();

  const [value, setValue] = useState<Date | null>(new Date());

  useEffect(() => {
    value && onChange && onChange(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className={classes.inputContainer}>
      <label>{label}</label>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={noLocale}>
        <DesktopDatePicker
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          OpenPickerButtonProps={{
            className: classes.white
          }}
          className={classes.field}
          inputFormat="dd.MM.yyyy."
          mask="__.__.____."
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              className={classes.field}
              InputProps={{ ...params.InputProps, className: classes.fontSize, disableUnderline: true }}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};
