import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReservationContext } from "../shared/state";
import { calcUnitPrice, ComplexBookingContext } from "./state";

type Props = {};

export const UnitPrice: FC<Props> = () => {
  const { t } = useTranslation("group-booking");
  const { state: complexState } = useContext(ComplexBookingContext);
  const { state: reservationState } = useContext(ReservationContext);
  const unitPrice = calcUnitPrice(reservationState,complexState);
  
  return (
    <>
      {unitPrice.toFixed()}{t('steps.nokPerPerson')}
    </>
  );
};
