import { Card, CardContent, CardHeader, Grid, IconButton } from "@mui/material";
import React, { FC, useContext } from "react";
import { ReservationContext } from "../shared/state";
import FeedIcon from "@mui/icons-material/Feed";
import { Badge } from "../shared/components/Badge";
import { Line } from "../shared/components/Line";
import { ColoredText } from "../shared/components/ColoredText";
import CloseIcon from "@mui/icons-material/Close";
import { DetailsContext } from "../../details/state";
import { useStatePreviewStyles } from "../complex/styles";
import moment from "moment";
import { useActivityPreviewStyles } from "./styles";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from "react-i18next";

type Props = {
  headerTitle: string;
  onClose?: () => any;
};

export const StatePreview: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation('activity-booking')
  const classes = useStatePreviewStyles();
  const classesActivityPreview = useActivityPreviewStyles();

  const { state: reservationState } = useContext(ReservationContext);

  const totalPrice = reservationState.totalPrice;

  return (
    <Card className={classes.bookingCard}>
      <CardHeader
        title={
          <div className={classes.cardHeaderContainer}>
            <div>
              <Badge>
                <FeedIcon />
              </Badge>{" "}
              {t("summary.cardTitle")}
            </div>
            {onClose ? (
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </div>
        }
        className={classes.cardHeader}
      />
      <CardContent>
        <Grid container className={classes.summaryGridContainer}>
          <Grid item xs={3} className={classes.namesGridContainer}>
            <b>{t("summary.labels.activity")}</b>
          </Grid>
          <Grid item xs={9} className={classes.activityBookingCartGrid}>
            <p>{reservationState.activity?.name}</p>
          </Grid>
          <Grid item xs={3} className={classes.namesGridContainer}>
            <b>{t("summary.labels.date")}</b>
          </Grid>
          <Grid item xs={9} className={classes.activityBookingCartGrid}>
            <p>{reservationState.date && moment(reservationState.date).format("DD.MM.yyyy.")}</p>
          </Grid>
          <Grid item xs={3} className={classes.namesGridContainer}>
            <b>{t("summary.labels.start")}</b>
          </Grid>
          <Grid item xs={9} className={classes.activityBookingCartGrid}>
            <p>{reservationState.timeSlot?.startTime && <>{moment(reservationState.timeSlot?.startTime).format("HH:mm")} h</>}</p>
          </Grid>
          <Grid item xs={3} className={classes.namesGridContainer}>
            <b>{t("summary.labels.duration")}</b>
          </Grid>
          <Grid item xs={9} className={classes.activityBookingCartGrid}>
            <p>{reservationState.priceInfo?.duration && <>{reservationState.priceInfo?.duration / 60} h</>}</p>
          </Grid>
          <Grid item xs={3} className={classes.namesGridContainer}>
            <b>{t("summary.labels.atendees")}</b>
          </Grid>
          <Grid item xs={9} className={classes.activityBookingCartGrid}>
            <p>{reservationState.count}</p>
          </Grid>
        </Grid>
        <div className={classesActivityPreview.note}>
          <InfoOutlinedIcon />
          <span>{t("summary.note")}</span>
        </div>
        <Line />
        <div className={classes.totalPrice}>
          <ColoredText>{t("summary.total")}:</ColoredText> {totalPrice || 0} Kr.
        </div>
      </CardContent>
    </Card>
  );
};
