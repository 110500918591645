import { Reservation, ReservationInfo } from "./timeslot.model";

export class TimeSlotBookings {
    private reservationInfos: Array<ReservationInfo>;
    constructor(private reservations: Array<Reservation>) {
        this.reservationInfos = this.mapReservationsInfos();
    }

    private mapReservationsInfos(): Array<ReservationInfo> {
        var reservationMap = this.reservations.reduce((acc: Map<string, ReservationInfo>, r: Reservation) => {
            var reservation = acc.get(r.reservationId);
            if (!reservation) {
                acc.set(r.reservationId, new ReservationInfo(r));
            }
            else{
                reservation.addReservationInfo(r);
            }
            return acc;
        }, new Map<string, ReservationInfo>());
        return Array.from(reservationMap.values());
    }

    public getReservations(): Array<ReservationInfo> {
        return this.reservationInfos;
    }
    public getUnRegistered(): Array<Reservation>{
        return this.reservationInfos
            .filter(x=>!x.isCheckedin())
            .map(x=>x.reservation);
    }
}