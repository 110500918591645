import { TextField } from "@mui/material";
import { FC } from "react";
import { useInputStyles } from "../../complex/styles";

type Props = {
  label?: JSX.Element;
  value?: string;
  placeholder?: string;
  onChange?: (v: string) => any;
};

export const TextAreaInput: FC<Props> = ({
  value = "",
  onChange = () => {},
  placeholder = "",
}) => {
  const classes = useInputStyles();

  return (
    <TextField
      variant="standard"
      value={value}
      onChange={e => onChange(e.target.value)}
      className={`${classes.field} ${classes.unsetMaxWidth}`}
      multiline
      InputProps={{ disableUnderline: true }}
      inputProps={{ style: { height: '100%' } }}
      placeholder={placeholder}
    />
  );
};
