import { Button, Card, CardActions, CardContent } from "@mui/material"
import HomeIcon from '@mui/icons-material/Home';
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useResource } from "../shared/hooks/useResource";

export const SignConfirmation = () => {
  const { t } = useTranslation('waiver');

  const classes = useStyles();
  const { refId } = useParams<{ refId?: string }>();
  const { resource } = useResource(refId);
  return (
    <Card className={classes.rootConfirm}>
      <CardContent className={classes.confirmationText}>
        <p>
          {t('signConfirmation.successMessage')}
        </p>
        <p>{t('signConfirmation.welcomeMessage')}</p>
      </CardContent>
      <CardActions className={classes.centerColumn}>
        <Button href={`/egenerklering/${resource?.prittyUrl}`} color="primary">
          <HomeIcon />{t('signConfirmation.startNew')}
        </Button>
      </CardActions>
    </Card>
  );
}