export class Product {
  public name: string = "";
  public formGroup: string = "";
  public multiselect: boolean = false;
  public items: Array<ProductItem> = new Array<ProductItem>();

  public static deSerialize(json: any): Product {
    var p = new Product();
    p.name = json.name || "NA";
    p.formGroup = json.formGroup || "NA";
    p.multiselect = json.multiselect !== undefined ? json.multiselect : false;
    p.items = json.items?.filter((item: any) => item.enabled).map((i: any) => ProductItem.deSerialize(i)) || [];
    return p;
  }
}

export class ProductItem {
  public id: string = "";
  public group: string = "";
  public name: string = "";
  public description: string = "";
  public price: number = 0;
  public selected: boolean = false;
  public defaultCheckedItem: boolean = false;
  public prOrder: boolean = false;
  public imageUrl: string = "";
  public enabled: boolean = true;
  public static deSerialize(json: any): ProductItem {
    var p = new ProductItem();
    p.id = json.id;
    p.name = json.name || "NA";
    p.description = json.description || "NA";
    p.price = json.price || 0;
    p.selected = json.selected;
    p.defaultCheckedItem = json.defaultCheckedItem;
    p.prOrder = json.prBestilling || json.prOrder;
    p.imageUrl = json.imageUrl;
    p.enabled = json.enabled;
    return p;
  }
}
