/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { FC, useEffect, useState } from "react";
import Loading from "../shared/components/Loading";
import { observableAxios } from "../system/rxjs-axios";
import { WaverFromService } from "./services/waver-from.service";

import { SignatureLogService } from "../signature-log/signature-log.service";
import { useStyles } from "./styles";
import { TENANT_ID } from "../environment";
import { Guid } from "../system/guid";
import { SignatureLog } from "../signature-log/signature-log";
import moment from "moment";
import { Details } from "../details/state";
import { BookableResource } from "../shared/model/bookable-resource";
import { TextInput } from "../booking/shared/components/TextInput";
import { useTranslation } from "react-i18next";
import { useWaiverRules } from "./services/useWaiverRules";
import { useLocation, useParams } from "react-router-dom";

type Props = {
  resource?: BookableResource;
  onSuccess: () => any;
  state: Details;
  additionalText?: string;
  signButtonLabel?: string;
  setMobile: (mobile: string) => any;
};

export const WaiverInfoComponent: FC<Props> = ({
  resource,
  state,
  onSuccess,
  additionalText,
  setMobile
}) => {

  const { t } = useTranslation('waiver');

  const classes = useStyles();

  const [service] = useState(
    new WaverFromService(
      observableAxios,
      new SignatureLogService(observableAxios)
    )
  );

  const location = useLocation();
  const passedMobile = decodeURIComponent(new URLSearchParams(location.search).get('mobile') ?? "");

  useEffect(() => {
    if(passedMobile && passedMobile !== state.mobile) {
      setMobile(passedMobile);
    }
  }, [passedMobile, state.mobile]);

  const { lang } = useParams<{ lang: string }>();
  const { rules } = useWaiverRules(lang);

  const [checked, setChecked] = useState<boolean>(false);
  const [checkedkampanje, setCheckedkampanje] = useState<boolean>(false);
  const [confirmSmSCode, setConfirmSmSCode] = useState<boolean>(false);
  const [runningCodeCheck, setRunningCodeCheck] = useState<boolean>(false);
  const [confirmationOk, setConfirmationOk] = useState<boolean>(false);
  const [confirmationLog, setConfirmationLog] = useState<SignatureLog>();
  const [isConfirmationError, setIsConfirmationError] =
    useState<boolean>(false);

  const [message, setMessage] = useState<string>("");

  const tenantId = resource ? resource.tenantId : TENANT_ID;
  const documentId = state.id;
  const requestConfirm = () => {
    setConfirmSmSCode(true);
    setRunningCodeCheck(true);

    setConfirmationLog({
      id: Guid.empty().toString(),
      documentId,
      logEntry: { mobile: state.mobile, code: "" },
      occuredTime: moment(),
      tenantId: tenantId,
    });

    service
      .requestConfirmation(
        documentId,
        state.mobile,
        tenantId,
        confirmationLog?.logEntry?.code
      )
      .subscribe(
        () => {
          setMessage("");
          setIsConfirmationError(false);
          setRunningCodeCheck(false);
        },
        (error: any) => {
          if (error.response.status === 400) {
            setMessage(t("errors.mobileNumber"));
          } else if (error.response.status === 424) {
            setMessage(t("errors.machine"));
          } else if (error.response.status >= 500) {
            setMessage(t("errors.server"));
          } else {
            setMessage(t("errors.something"));
          }
          setRunningCodeCheck(false);
          setIsConfirmationError(true);
        }
      );
  };

  const submitCode = () => {
    setRunningCodeCheck(true);
    service
      .confirmWaiver({
        waiverRules: rules,
        waiver: {
          ...state,
          advertising: checkedkampanje,
          tenantId,
          signingdate: moment().utc(),
          referenceId: resource?.id ?? "",
        },
        signature: {
          ...confirmationLog,
          occuredTime: moment().utc(),
        } as SignatureLog,
      })
      .subscribe(
        () => {
          setRunningCodeCheck(false);
          setConfirmationOk(true);
          setMessage("");
          setIsConfirmationError(false);
          onSuccess();
        },
        (error: any) => {
          if (error.response.status === 400) {
            setMessage("Feil kode prøv igjen!");
          } else if (error.response.status === 424) {
            setMessage("Rusk i maskineriet. Prøv en gang til!");
          } else if (error.response.status >= 500) {
            setMessage(
              "Det er noe galt på serveren. Vi klarte dessverre ikke å fulføre signeringen. Prøv igjen senere."
            );
          } else {
            setMessage("Noe gikk galt. Prøv igjen!");
          }

          setRunningCodeCheck(false);
          setIsConfirmationError(true);
        }
      );
  };

  return (
    <>
      <span>{additionalText}</span>

      <ul className={classes.robotoRegular}>
        {rules.map((rule) => (
          <li key={rule}>{rule}</li>
        ))}
      </ul>

      <div className="list-container">
        <FormControlLabel
          control={<Checkbox onChange={() => setChecked(!checked)} />}
          label={
            <label className={classes.smallText}>
              {t('consent.personal')}
            </label>
          }
        />
        <FormControlLabel
          control={
            <Checkbox onChange={() => setCheckedkampanje(!checkedkampanje)} />
          }
          label={
            <label className={classes.smallText}>
              {t('consent.marketing')}
            </label>
          }
        />
      </div>

      {confirmSmSCode && (
        <section>
          <h2 className={classes.robotoThin}>{t('signing.title')}</h2>

          <div className="box">
            <div>
              {runningCodeCheck && (
                <div>
                  <Loading />
                </div>
              )}
              {!runningCodeCheck && (
                <div style={{marginBottom: '1em'}}>
                  <h4>{t('signing.confirmWithSmsCode')}!</h4>
                  {isConfirmationError && (
                    <p>
                      <span className="messageFont">{message}</span>
                      <span>
                        <Button variant="contained" onClick={requestConfirm}>
                          {t('signing.sendNewCode')}
                        </Button>
                      </span>
                    </p>
                  )}
                  <p>
                    {t('signing.codeSentTo')} :{" "}
                    <strong>{confirmationLog?.logEntry?.mobile}</strong>
                  </p>
                  <TextInput
                    label="Sms kode"
                    variant="standard"
                    value={confirmationLog?.logEntry?.code ?? ""}
                    onChange={(e) =>
                      confirmationLog?.logEntry &&
                      setConfirmationLog({
                        ...confirmationLog,
                        logEntry: {
                          ...confirmationLog.logEntry,
                          code: e.target.value,
                        },
                      })
                    }
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitCode}
                  >
                    {t('signing.confirm')}
                  </Button>
                </div>
              )}
            </div>
            {confirmationOk && <h2>{t('signing.signedOk')}!</h2>}
          </div>
        </section>
      )}
      <div className={classes.buttonsContainer}>
        <div></div>
        <Button
          variant="contained"
          color="primary"
          onClick={requestConfirm}
          disabled={!checked || confirmSmSCode}
        >
          {t('signing.button')}
        </Button>
      </div>
    </>
  );
};
