import { createTheme } from "@mui/material";

declare module '@mui/material/styles' {
  interface Theme {
    border?: {
      radius?: string;
    };
  }
  interface ThemeOptions {
    border?: {
      radius?: string;
    };
  }
}

export const theme = createTheme({
  border: {
    radius: '10px'
  },
  palette: {
    primary: {
      main: "#E71483",
    },
    background: {
      default: '#130d14',
      paper: "#241926"
    },
    text: {
      primary: '#fff'
    }
  },
  typography: {
    allVariants: {
      color: "#fff"
    },
    h1: {
      fontWeight: "bold",
      fontSize: "2em",
    },
    h2: {
      fontSize: "2em",
    },
    h3: {
      fontSize: "1.5em",
    },
    h4: {
      fontSize: "1.5em",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#241926",
        },
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#fff'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
        },
        caption: {
          color: '#fff'
          
        }
      }
    }
  },
});
