/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useState } from "react";
import { DateInput } from "../shared/components/DateInput";
import { SelectInput } from "../shared/components/SelectInput";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { ReservationContext } from "../shared/state";
import { Reservation } from "../model/timeslot.model";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { PriceInfo } from "../../shared/model/day-schedule.model";
import { useTranslation } from "react-i18next";
import { useTimeScheduleService } from "../services/time-schedule.service";
import { Activity } from "../model/activity.model";
import {
  getAvailableTimeSlotsFor,
  TimeSlotReservations,
} from "../services/timeSlotUtils";
import { useInputStyles } from "./styles";
import { ComplexBookingContext, getSelectedDuration } from "./state";

type Props = {
  activities?: Activity[];
  minAtendees?: number;
  needsLongerDuration?: boolean;
  isActivityBooking?: boolean;
  needsMoreActiveties?: boolean;
};

const maxAtendees = 50;

export const DateTimeAtendees: FC<Props> = ({
  activities,
  minAtendees = 5,
  needsLongerDuration = false,
  needsMoreActiveties = false,
  isActivityBooking = false,
}) => {
  const { t } = useTranslation("date-time-atendees");

  const classes = useInputStyles();
  const { state: complexState } = useContext(ComplexBookingContext);
  const { state, dispatch } = useContext(ReservationContext);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const packageDuration = query.get("packageDuration") || "";
  const parsedPackageDuration = parseInt(packageDuration);

  const isPackage = !!packageDuration;

  const allActivities = activities || (state.activity ? [state.activity] : []);
  const [allReservations, setAllReservations] = useState<Reservation[]>();
  const [duration, setDuration] = useState<number | null>();
  const [durationId, setDurationId] = useState<string>("");
  const [durations, setDurations] = useState<PriceInfo[]>([]);

  const [availableTimeSlotReservations, setAvailableTimeSlotReservations] =
    useState<TimeSlotReservations[]>([]);

  const { timeScheduleService } = useTimeScheduleService();
  const hasDefaultSubActivity = state.hasDefaultSubActivity;
  const selectedDuration = getSelectedDuration(state, complexState);

  useEffect(() => {
    if(!isNaN(parsedPackageDuration)){
      const duration = parsedPackageDuration;
      setDuration(duration);
      const priceInfo = findPrice(duration);
      if (priceInfo) {
        setDurationId(priceInfo.viewValue);
      }
    } else {
      var firstDuration = durations[0];
      if (firstDuration) {
        setDuration(firstDuration.duration);
        setDurationId(firstDuration.viewValue);
      }
    }
  }, [parsedPackageDuration,durations]);
  useEffect(() => {
    if (state.date === null) return;
    const allResourceIds = allActivities.reduce<string[]>(
      (acc, curr) => [...acc, curr.id,...curr.getRequiredResourceIds()],
      []
    );
    timeScheduleService
      .getReservationsFor(allResourceIds, moment(state.date))
      .subscribe((reservations) => {
        setAllReservations(reservations);
      });
  }, [state.activity, activities, state.date]);
  const findPrice = (duration: number) => {
    const possiblePrice = durations.filter((x) => x.duration === duration);
    const priceInfo = possiblePrice.length?possiblePrice[0]:null;
    return priceInfo;
  }
  const findPriceId = (durationId: string) => {
    return durations.find((x) => x.viewValue === durationId);
  }
  useEffect(() => {
    if (
      !allReservations ||
      !state.count ||
      duration == null ||
      state.date === null
    )
      return;
    const priceInfo = findPriceId(durationId);
    const price = priceInfo?.price??0;
    
    const availableTimeslots =
      state.activity &&
      getAvailableTimeSlotsFor(
        state.activity.id,
        allActivities,
        allReservations,
        state.count,
        duration,
        state.date,
        price,
        complexState
      );
    setAvailableTimeSlotReservations(availableTimeslots);
    dispatch({ type: "SET_TIME_SLOT", data: null });
  }, [allReservations, state.date, state.count, duration]);

  useEffect(() => {
    if (!state.activity) return;
    var durations = state.activity
    .getDurations(moment(state.date), query.get("campaign") ?? "")
    .filter((x) => !x.subActivityPrice);
    setDurations(durations);
  }, [state.date, state.activity, query.get("campaign")]);

  useEffect(() => {
    if (durationId) {
      const priceInfo: PriceInfo | undefined = findPriceId(durationId)??undefined;
      dispatch({ type: "SET_PRICE_INFO", data: priceInfo });
    }
  }, [durationId, durations]);

  useEffect(() => {
    // const stateDuration = state.priceInfo?.duration??0;
    if (
      state.priceInfo?.duration &&
      selectedDuration > state.priceInfo?.duration
    ) {
      setDuration(selectedDuration);
    }
  }, [selectedDuration]);

  return (
    <>
      <div className={classes.fieldContainer}>
        <DateInput
          label={
            <>
              *{t("labels.date")} <InsertInvitationIcon />
            </>
          }
          onChange={(date) => {
            allActivities.forEach((a) => a.build(moment(date)));
            dispatch({ type: "SET_DATE", data: date });
          }}
        />
        <span className={classes.errorMessage}>
          {!state.date ? t("errors.needsDate") : ""}
        </span>
      </div>
      <div className={classes.fieldContainer}>
        <SelectInput
          label={
            <>
              *{t("labels.atendees")} <PeopleRoundedIcon />
            </>
          }
          value={state.count ?? ""}
          onChange={(c) => dispatch({ type: "SET_COUNT", data: c })}
          options={Array.from(
            { length: maxAtendees - minAtendees + 1 },
            (_, i) => ({
              label: i + minAtendees!,
              value: i + minAtendees!,
            })
          )}
        />
        <span className={classes.errorMessage}>
          {!state.count ? t("errors.needsCount") : ""}
        </span>
      </div>
      <div className={classes.fieldContainer}>
        <SelectInput
          label={"*" + t("labels.duration")}
          value={durations.length > 0 ? durationId ?? "" : ""}
          onChange={(e: any) =>{            
              const duration = findPriceId(e)?.duration??0;
              if(!(!isActivityBooking && isPackage && duration)) {
                setDuration(duration);
                setDurationId(e);
              }
            }
          }
          options={
            !durations
              ? []
              : durations.map((p) => ({
                  label: `${p.viewValue}${isActivityBooking?(" " + p.price + ",- kr"):""}`,
                  value: p.viewValue,
                }))
          }
          disabled={!isActivityBooking && isPackage}
        />
        <span className={classes.errorMessage}>
          {!state.priceInfo ? t("errors.needsDuration") : ""}
          {needsLongerDuration ? t("errors.needsLongerDuration") : ""}
          {needsMoreActiveties ? t("errors.needsMoreActiveties") : ""}
        </span>
      </div>
      <div className={classes.fieldContainer}>
        <SelectInput
          label={
            <>
              *{t("labels.time")} <AccessTimeIcon />
            </>
          }
          value={state?.timeSlot?.id ?? ""}
          onChange={(e: any) => {
            const timeSlotReservations = availableTimeSlotReservations.find(
              (ts) => ts.timeSlot.id === e
            );
            dispatch({
              type: "SET_TIME_SLOT",
              data: timeSlotReservations?.timeSlot,
            });
            dispatch({
              type: "SET_RESERVATIONS",
              data: timeSlotReservations?.reservations,
            });
          }}
          options={
            availableTimeSlotReservations &&
            availableTimeSlotReservations.map((t) => ({
              label: t.timeSlot.start().format("HH:mm"),
              value: t.timeSlot.id,
            }))
          }
        />
        <span className={classes.errorMessage}>
          {!state.timeSlot ? t("errors.pickTimeSlot") : ""}
        </span>
      </div>
    </>
  );
};
